<template>
    <div class="con">
        <el-row class="tac" style="height: 100%;">
            <el-col :span="24">
                <el-menu
                    default-active="1"
                    class="el-menu-vertical-demo"
                    @select="selectMenu"
                    @open="handleOpen"
                    @close="handleClose"
                    background-color="#f7f7f7"
                    text-color="#000000"
                    active-text-color="#017ED8">
                    <el-menu-item :index="'menu-' + (index + 1)" v-for="(item,index) in MenuList" :key="index" class="menu_style">
                        <span slot="title" style="font-size: 17px;">{{ item.name }}</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {getMenuList} from "@/api";

export default {
    name: 'customMenu',
    data() {
        return {
            MenuList: [],
            selectedId: '',
        }
    },
    mounted() {
        this.menuList()
        this.$emit('menuIndex',1);
    },
    methods: {
        menuList(){
            getMenuList().then(res=>{
                if(res.data.code === 1){
                    this.MenuList = res.data.data
                    this.selectedId = this.MenuList[0].id;
                    this.$emit('menuId',this.selectedId);
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        onSubmit(){

        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        selectMenu(e) {
            const parts = e.split('-');
            const index = parts.length > 1 ? parseInt(parts[1]) : null;
            this.$emit('menuIndex',index);
            this.selectedId = this.MenuList[index - 1].id;
            this.$emit('menuId',this.selectedId);
        },
    }
}
</script>
<style scoped>

.con{
    height: 84%;
    border: 1px #d6d8dc solid;
    background-color:#f7f7f7;
}
.menu_style{
    border-bottom: 1px #d6d8dc solid;
    height: 40px;
    line-height: 40px;
}

</style>
