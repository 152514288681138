<template>
    <div class="con" id="files">
        <div style="margin-top: 1%;display: flex;justify-content: space-between;">
            <div style="width: 45%;height: 100%;">
                <div style="width: 100%;display: flex;align-items: center;justify-content: left;margin-top: 1%;" v-for="index in selectNum" :key="index" >
                    <div style="width: 15%;margin-left: 6px;white-space: nowrap;"></div>
                    <div>
                        <el-select v-model="value[index]" filterable placeholder="请选择" @change="change">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div><i class="el-icon-caret-right" style="color: #0e92dd;"></i></div>
                </div>
                <div style="width: 100%;display: flex;;justify-content: center;">
                    <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #ffffff;" type="primary" @click="addSelect">添加</el-button>
                    <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #ffffff;" type="primary" @click="deleteSelect">删除</el-button>
                </div>
            </div>
            <div style="width: 50%;height: 300px;border: 1px #dddddd solid;overflow-y: auto;margin-right: 1rem;">
                <div style="font-size: 15px;padding: 5px;word-wrap: break-word;overflow-wrap: break-word;" v-html="Introduction"></div>
            </div>
        </div>
        <div style="width: 100%;display: flex;;justify-content: center;">
            <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #ffffff;" type="primary" @click="success">完成</el-button>
        </div>
    </div>
</template>
<script>
import {disease, filesAdd, medicalRecords, medicalRecordsDelAll} from "@/api";

export default {
    props: ['patient_id'],
    name: 'addFiles',
    data() {
        return {
            Introduction: '',
            value:{},
            options: [],
            keyword: '',
            //下拉框添加数量
            selectNum: 1,
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.diseaseInfo()
        this.init()
    },
    methods: {
        init(){
            medicalRecords({
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.selectNum = res.data.data.total
                    this.userFilesList = res.data.data.data
                    if(this.userFilesList.length > 0){
                        this.Introduction = this.userFilesList[0].disease.intro;
                        for (let i = 0; i < this.userFilesList.length; i++) {
                            this.value[i+1] = this.userFilesList[i].disease_id
                        }
                    }
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //完善信息-下拉框选择事件
        change(e){
            for (let item of this.options) {
                if (item.id === e) {
                    this.Introduction = item.intro;
                    break;
                }
            }
        },
        //完善信息-新增下拉框
        addSelect(){
            if(this.selectNum >= 6 ) {
                this.selectNum = 6
                this.$message({
                    message: '最多添加六条',
                    type: 'success'
                });
            }else{
                this.selectNum ++
            }
        },
        //完善信息-新增下拉框
        deleteSelect(){
            if(this.selectNum <= 1 ) {
                this.selectNum = 1
                this.$message({
                    message: '最少保留一条',
                    type: 'success'
                });
            }else{
                this.selectNum --
                const keys = Object.keys(this.value);
                for (let i = this.selectNum; i < keys.length; i++) {
                    delete this.value[keys[i]];
                }
            }
        },
        //完善信息-获取疾病简介列表
        diseaseInfo(){
            disease().then(res=>{
                if(res.data.code === 1){
                    this.options = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //完成
        success(){
            const values = Object.values(this.value);
            const result = values.join(',');
            const set = new Set(values)
            let is_appear = set.size !== values.length
            if(is_appear){
                this.$message({
                    message: '相同的选项只会添加一条',
                    type: 'warning'
                });
            }
            medicalRecordsDelAll({
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    filesAdd({
                        disease_id: result,
                        user_id: this.user_id,
                    }).then(res=>{
                        if(res.data.code === 1){
                            this.$emit('filesAddComplete');
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
.con{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    text-align: center;
}
</style>
