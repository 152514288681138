<template>
  <div class="rtc-container">
    <div class="info-container" :class="$isMobile && 'info-container-mobile'">

      <div v-if="isHostMode" class="log-container" ref="logContainer" style="display: none">
        <p class="log-label">日志:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div>

      <div style="width: 100%;height: 600px;display: flex;">
          <div style="width: 70%;height: 100%;">
              <div style="width: 100%;height: 85%;display: flex;justify-content: center;margin-left: 2.5%;">
                  <div v-show="camStatus === 'started'" :class="is_zoom === 2 ? 'local-stream-container is_zoom_style' : 'local-stream-container'" style="width: 30%;height: 30%;object-fit: contain;border: 1px #99a9bf solid;border-radius: 10px;">
                      <div id="local" class="local-stream-content"></div>
                        <!-- @click="zmmoIn" 会议发起人的放大功能-->
                  </div>
<!--                  <div v-show="camStatus !== 'started'" style="width: 30%;height: 30%;object-fit: contain;border-radius: 10px;border: 1px #ccc solid;border-radius: 10px;">-->
<!--                      <div>-->
<!--                          {{ meeting_recordsShow_list_arr.oneself.name }}-->
<!--                      </div>-->
<!--                  </div>-->
                  <div class="remote-container" style="margin-left: 2%;">
                      <div v-for="(item,index) in remoteUsersViews"
                           :key="index"
                           :id="item"
                           style="width: 40%;height: 30%;"
                           :class="is_zooms === 2 && index === is_zooms_index ? 'remote-stream-container is_zoom_styles' : 'remote-stream-container'">
                            <!--@click="zmmoIns(index)"  被邀请人的放大功能-->
                      </div>
                  </div>
              </div>
              <div style="width: 100%;height: 15%;border-top: 2px #eeeeee solid;display: flex;align-items: center;justify-content: space-between;">
                  <div style="width: 20%;"></div>
                  <div>
                      <el-button
                              v-if="!isMutedAudio"
                              class="button"
                              size="small"
                              @click="muteAudio">
                          <i class="el-icon-microphone"></i>
                      </el-button>
                      <el-button
                              v-if="isMutedAudio"
                              class="button"
                              size="small"
                              @click="unmuteAudio">
                          <i class="el-icon-turn-off-microphone"></i>
                      </el-button>
                      <el-button
                              v-if="!isMutedVideo"
                              class="button"
                              size="small"
                              @click="muteVideo">
                          <i class="el-icon-camera"></i>
                      </el-button>
                      <el-button
                              v-if="isMutedVideo"
                              class="button"
                              size="small"
                              @click="unmuteVideo">
                          <i class="el-icon-camera-solid"></i>
                      </el-button>
                      <el-button
                              class="button"
                              size="small"
                              :loading="shareStatus === 'sharing'"
                              :disabled="shareStatus === 'shared'"
                              @click="handleStartScreenShare">
                          <i class="el-icon-data-line"></i>共享
                      </el-button>
                      <el-button
                              class="button"
                              size="small"
                              :loading="shareStatus === 'stopping'"
                              @click="handleStopScreenShare">
                          <i class="el-icon-data-line"></i>结束共享
                      </el-button>
                  </div>
                  <div class="control-container" style="display: flex;justify-content: space-between;margin-right: 1rem;">
                      <div class="rtc-control-container">
                          <el-button
                                  class="button1"
                                  type="primary"
                                  size="mini"
                                  :loading="roomStatus === 'entering'"
                                  :disabled="roomStatus === 'entered'"
                                  @click="handleEnterRoom">进入房间1</el-button>
                          <el-button
                                  class="button"
                                  type="danger"
                                  size="mini"
                                  :loading="roomStatus === 'exiting'"
                                  @click="handleExit">离开房间</el-button>
                      </div>
                  </div>
              </div>
          </div>
          <div style="width: 30%;height: 100%;border-radius: 0 0 10px 0;border-left: 2px #cccccc solid;">
              <div style="width: 100%;height: 40%;margin: auto;overflow-y: auto;padding-bottom: 1rem">
                  <div style="display: flex;align-items: center;justify-content: space-between;padding: 0.5rem 1.5rem;" v-for="(item,index) in meeting_recordsShow_list" :key="index">
                      <div style="display: flex;align-items: center;">
                          <div style="border-radius: 5px;"><img :src="item.pic" style="width: 25px;height: 25px;border-radius: 5px;object-fit: cover;" alt=""></div>
                          <div>{{ item.name }}</div>
                      </div>
                      <div style="display: flex;align-items: center;">
                          <div><img src="@/assets/mai.png" style="width: 15px;height: 15px;" alt=""></div>
                          <div><img src="@/assets/she.png" style="width: 15px;height: 15px;" alt=""></div>
                      </div>
                  </div>
              </div>
              <div style="width: 100%;height: 10%;margin: auto;">
                    <div style="width: 90%;margin: auto;height: 100%;border-top: 1px #eeeeee solid;display: flex;justify-content: center;align-items: center;">
                        <el-button style="width: 40%;border-radius: 8px;" size="small" @click="updateRoom_audio">{{ updateRoom_audio_is === 1 ? '全体静音' : '解除静音' }}</el-button>
                        <el-button style="width: 40%;border-radius: 8px;background-color: #b6e300" size="small" @click="Invitation">邀请</el-button>
                      <el-tooltip class="item" effect="dark" content="点击按钮复制会议室消息邀请加入会议" placement="right" popper-class="tool-class">
                        <i class="el-icon-warning"></i>
                      </el-tooltip>
<!--                      <p style="color: red; font-size: 12px; position: relative; top: 10%; left: 20%; transform: translateX(-50%); margin-top: 5px;">点击按钮复制会议室消息邀请加入会议</p>-->
                    </div>

              </div>

              <div style="width: 100%;height: 40%">
                  <div style="width: 84%;height: 10%;margin: auto;background-color: #eeeeee;display: flex;justify-content: space-between;align-items: center;padding: 0 8%;">
                      <div>聊天对话</div>
                      <div><i class="el-icon-arrow-down"></i></div>
                  </div>
                  <div style="width: 100%;height: 75%;margin: auto;overflow-y: auto;" ref="scrollArea">
                      <div style="padding: 8px 10px 0 0;" v-for="(video_item,video_index) in video_im_info" :key="video_index">
                          <div style="text-align: right" v-if="user_id == video_item.im_binding.doctor.id">
                              <div v-if="video_item.msg_type === 'text' || video_item.msg_type === 'text_1'">
                                  <div style="padding: 2px 10px;">{{ video_item.im_binding.doctor.name }}</div>
                                  <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;text-align: left;">
                                      {{ video_item.content }}
                                  </div>
                              </div>
                              <div v-if="video_item.msg_type === 'image' || video_item.msg_type === 'image_1'">
                                  <div style="padding: 2px 10px;">{{ video_item.im_binding.doctor.name }}</div>
                                  <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;text-align: left;">
                                      <el-image
                                          style="width: 100px; height: 100px"
                                          :src="video_item.content"
                                          :preview-src-list="[video_item.content]">
                                      </el-image>
                                  </div>
                              </div>
                          </div>
                          <div v-else>
                              <div v-if="video_item.msg_type === 'text' || video_item.msg_type === 'text_1'">
                                  <div style="padding: 2px 10px;">{{ video_item.im_binding.doctor.name }}</div>
                                  <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;">
                                      {{ video_item.content }}
                                  </div>
                              </div>
                              <div v-if="video_item.msg_type === 'image' || video_item.msg_type === 'image_1'">
                                  <div style="padding: 2px 10px;">{{ video_item.im_binding.doctor.name }}</div>
                                  <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;">
                                      <el-image
                                          style="width: 100px; height: 100px"
                                          :src="video_item.content"
                                          :preview-src-list="[video_item.content]">
                                      </el-image>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
                  <div style="width: 100%;height: 15%;margin: auto;overflow-y: auto;border-top: 1px #eeeeee solid;">
                        <div style="width: 90%;height: 95%;margin: auto;border-bottom: 1px #cccccc solid;display: flex;align-items: center;justify-content: right;">
                            <div>
                                <div style="width: 20px;height: 20px;margin-right: 5px;" @click="open(1)"><img src="@/assets/image.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
                            </div>
                            <div style="width: 20px;height: 20px;margin-right: 5px;">
                                <el-popover
                                    placement="bottom"
                                    title="请选择表情"
                                    :width="300"
                                    trigger="click">
                                    <template #reference>
                                        <img src="@/assets/smiling_face.png" style="width: 100%;height: 100%" alt="Vue Image">
                                    </template>
                                    <chatIcon @changeText="getIconChild"/>
                                </el-popover>
                            </div>
                        </div>
                  </div>
                  <div style="width: 100%;height: 20%;margin: auto;overflow-y: auto;">
                      <div class="con_below_left_below1">
                          <el-input v-model="send_input" placeholder="输入内容" size="small" @keyup.native.enter="Send"></el-input>
                          <div class="comment_input_button">
                              <div style="width: 22px;height: 22px;background: #017ED8;border-radius: 8px;display: flex;justify-content: center;align-items: center;" @click="Send">
                                  <img src="@/assets/send.png" style="width: 18px;height: 18px" alt="Vue Image">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>

    </div>





<!--      <p v-if="isHostMode" class="label">操作</p>-->
<!--      <div class="control-container">-->
<!--          <div class="rtc-control-container">-->
<!--              <el-button-->
<!--                      class="button"-->
<!--                      type="primary"-->
<!--                      size="small"-->
<!--                      :loading="roomStatus === 'entering'"-->
<!--                      :disabled="roomStatus === 'entered'"-->
<!--                      @click="handleEnterRoom">进入房间</el-button>-->
<!--              <el-button-->
<!--                      class="button"-->
<!--                      type="primary"-->
<!--                      size="small"-->
<!--                      :loading="roomStatus === 'exiting'"-->
<!--                      @click="handleExit">离开房间</el-button>-->
<!--          </div>-->
          <!--      <div class="rtc-control-container">-->
          <!--          <el-button-->
          <!--                  class="button"-->
          <!--                  type="primary"-->
          <!--                  size="small"-->
          <!--                  @click="handleEnterRoom">开始录制</el-button>-->
          <!--          <el-button-->
          <!--                  class="button"-->
          <!--                  type="primary"-->
          <!--                  size="small"-->
          <!--                  @click="handleExit">结束录制</el-button>-->
          <!--      </div>-->
          <!--      <div class="rtc-control-container">-->
          <!--        <el-button-->
          <!--          v-if="isHostMode"-->
          <!--          class="button"-->
          <!--          type="primary"-->
          <!--          :loading="micStatus === 'starting'"-->
          <!--          :disabled="micStatus === 'started'"-->
          <!--          size="small" @click="handleStartLocalAudio">采集麦克风-->
          <!--        </el-button>-->
          <!--        <el-button-->
          <!--          v-if="isHostMode"-->
          <!--          class="button"-->
          <!--          type="primary"-->
          <!--          :loading="camStatus === 'starting'"-->
          <!--          :disabled="camStatus === 'started'"-->
          <!--          size="small" @click="handleStartLocalVideo">采集摄像头-->
          <!--        </el-button>-->
          <!--        <el-button-->
          <!--          v-if="isHostMode"-->
          <!--          class="button"-->
          <!--          :loading="micStatus === 'stopping'"-->
          <!--          type="primary" size="small" @click="handleStopLocalAudio">终止采集麦克风-->
          <!--        </el-button>-->
          <!--        <el-button-->
          <!--          v-if="isHostMode"-->
          <!--          class="button"-->
          <!--          :loading="camStatus === 'stopping'"-->
          <!--          type="primary" size="small" @click="handleStopLocalVideo">终止采集摄像头-->
          <!--        </el-button>-->
          <!--      </div>-->
<!--          <div v-if="isHostMode" class="screen-share-control-container">-->
<!--              <el-button-->
<!--                      class="button"-->
<!--                      type="primary"-->
<!--                      size="small"-->
<!--                      :loading="shareStatus === 'sharing'"-->
<!--                      :disabled="shareStatus === 'shared'"-->
<!--                      @click="handleStartScreenShare">开始屏幕共享</el-button>-->
<!--              <el-button-->
<!--                      class="button"-->
<!--                      type="primary"-->
<!--                      size="small"-->
<!--                      :loading="shareStatus === 'stopping'"-->
<!--                      @click="handleStopScreenShare">终止屏幕共享</el-button>-->
<!--          </div>-->
<!--      </div>-->

      <div v-if="showInviteLink" class="invite-link-container" style="display: none">
          <span>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
          <el-input class="invite-input" v-model="inviteLink" disabled>
              <template slot="prepend">
                  <el-tooltip
                          :visibleArrow="false"
                          effect="dark"
                          content="Copied!"
                          placement="bottom"
                          :manual="true"
                          v-model="showCopiedTip">
            <span class="invite-btn" @click="handleCopyInviteLink">
              <i class="el-icon-document-copy"></i>
            </span>
                  </el-tooltip>
              </template>
          </el-input>
      </div>

      <!-- 上传图片 -->
      <input type="file" style="display: none;" @change="uploads" accept=".jpg,.png,.JPG,.JPEG,.PNG" ref="Img" multiple />

  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import TRTC from 'trtc-sdk-v5';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import {
    joinRoom,
    exitRoom,
    addUserMeetingRecordsList,
    recordsEdit,
    uploadFile,
    meeting_recordsShow,
    updateRoomInfo,
    imMeetingGroupJoinGroup,
    imMeetingGroupShow,
    imMeetingGroupGetMsgList,
    imMeetingGroupSendToGroupMassage, chatDinding, startRecording, endRecording,
} from "@/api";
import chatIcon from './chatIcon.vue'

export default {
  name: 'compRoom',
    components: {
        chatIcon,
    },
  mixins: [rtc],
  props: {
    type: String,
    sdkAppId: Number,
    sdkSecretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
    joinRoom: Boolean
  },
  data() {
    return {
      upload_type: '',
      send_input: '',
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      isHostMode:true,
      meeting_recordsShow_list: [],
      meeting_recordsShow_list_arr: [],
      updateRoom_audio_is: 1,
      video_im_info: [],
      video_Group_id: '',
      user_id: '',
      client_id: '',
      user_im_id: '',
      user_role: '',
      is_zoom: 1,
      is_zooms: 1,
      is_zooms_index: 0,
      is_exit: 1,//判断当前用户是否退出房间
    };
  },
  mounted() {
      this.trtc = TRTC.create();
      this.user_id = localStorage.getItem('user_id');
      this.client_id = localStorage.getItem('client_id');
      this.user_role = localStorage.getItem('user_role');
      this.scrollToBottom()
      this.init_im()

      const videoElement = document.querySelector('.local-stream-container video');

      console.log(videoElement,'视频控件')

      if (videoElement) {
          videoElement.setAttribute('controls', true);
      }
  },
  computed: {
    // isHostMode() {
    //   return this.type !== 'invite';
    // },
    showInviteLink() {
      return this.isHostMode && this.roomStatus === 'entered' && this.inviteLink;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  methods: {
      //放大视频
      zmmoIn(){
          if(this.is_zoom === 1){
              this.is_zoom = 2
          }else{
              this.is_zoom = 1
          }
      },
      //点击放大其他人的
      zmmoIns(id){
          this.is_zooms_index = id
          if(this.is_zooms === 1){
              this.is_zooms = 2
          }else{
              this.is_zooms = 1
          }
      },
      scrollToBottom() {
          this.$nextTick(() => {
              this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
          });
      },
      videodata(e){
          if(this.video_Group_id === e.group_id){
              this.video_im_info.push(e)
              console.log(this.video_im_info,'这是聊天记录')
              this.scrollToBottom()
          }
      },
      //邀请入会
      Invitation(){
          const roomId = localStorage.getItem('roomid');
          if(this.userSig === undefined || this.userId === undefined || roomId === undefined){
              this.$message({
                  message: '请先进入房间再邀请成员',
                  type: 'success'
              });
              return
          }
          const url = window.location.href + '?roomId=' + encodeURIComponent(roomId);
          console.log(url);
          const urlInfo = '请通过下面链接进入' + roomId + '号会议室：' + url;
          this.copyToClipboard(urlInfo);
      },
      // 复制文本到剪贴板的方法
      copyToClipboard(text) {
          // 检查navigator.clipboard是否存在，以支持现代浏览器
          if (navigator.clipboard && navigator.clipboard.writeText) {
              navigator.clipboard.writeText(text)
                  .then(() => {
                      this.$message({
                          message: '邀请链接已复制',
                          type: 'success'
                      });
                  })
                  .catch(err => {
                      console.error('无法复制到剪贴板:', err);
                      this.$message({
                          message: '复制失败，请手动复制',
                          type: 'error'
                      });
                  });
          } else {
              // 对于不支持的浏览器，可以尝试使用旧的方法
              const textarea = document.createElement('textarea');
              textarea.value = text;
              document.body.appendChild(textarea);
              textarea.select();
              try {
                  const successful = document.execCommand('copy');
                  if (successful) {
                      this.$message({
                          message: '邀请链接已复制',
                          type: 'success'
                      });
                  } else {
                      this.$message({
                          message: '复制失败，请手动复制',
                          type: 'error'
                      });
                  }
              } catch (err) {
                  console.error('无法复制到剪贴板:', err);
                  this.$message({
                      message: '复制失败，请手动复制',
                      type: 'error'
                  });
              }
              document.body.removeChild(textarea);
          }
      },
      updateRoom_audio(){
          let is_audio_disabled = 0
          if(this.updateRoom_audio_is === 1){
              is_audio_disabled = 0
              this.updateRoom_audio_is = 0
          }else{
              is_audio_disabled = 1
              this.updateRoom_audio_is = 1
          }
          const roomId = localStorage.getItem('roomid');
          updateRoomInfo({
              id: roomId,
              is_audio_disabled: is_audio_disabled,
          }).then(res=>{
              if(res.data.code === 1){
                  if(res.data.code === 1){
                      this.$message({
                          message: res.data.msg,
                          type: 'success'
                      });
                  }else{
                      this.$message({
                          message: '网络错误',
                          type: 'warning'
                      });
                  }
              }
          })
      },
      //获取人员列表
    meeting_recordsShow_init(){
        const roomId = localStorage.getItem('roomid');
        meeting_recordsShow({
            id: roomId,
        }).then(res=>{
            if(res.data.code === 1){
                this.meeting_recordsShow_list = res.data.data.doctor_attendees
                const arrays =  res.data.data.doctor_attendees
                const arrs = {
                    oneself: arrays.find(doctor => doctor.id === Number(this.user_id)),
                    someone: arrays.filter(doctor => doctor.id !== Number(this.user_id)),
                }
                this.meeting_recordsShow_list_arr = arrs
            }
        })
    },
    //选择表情
    getIconChild(e){
        this.send_input += e
    },
    //图片上传选择文件
    open(type){
        this.upload_type = type
        this.$refs.Img.click();
    },
      //发送消息
      Send(){
          if(this.is_exit === 2){
              this.$message({
                  message: '已退出会议无法发送消息',
                  type: 'warning'
              });
              return
          }
          const video_im_id = localStorage.getItem('video_im_id');
          imMeetingGroupSendToGroupMassage({
              group_id: this.video_Group_id,
              sender_im_id: video_im_id,
              msg_type: 'text',
              content: this.send_input,
          }).then(res=>{
              if(res.data.code === '1'){
                  console.log(res)
                  this.send_input = ''
                  // imMeetingGroupGetMsgList({
                  //     group_id: this.video_Group_id,
                  // }).then(res2=>{
                  //     if(res2.data.code === '1'){
                  //         this.video_im_info = res2.data.data.data
                  //         this.scrollToBottom()
                  //     }
                  // })
              }else{
                  this.$message({
                      message: res.data.msg,
                      type: 'warning'
                  });
              }
          })
      },
      uploads(event) {
          const video_im_id = localStorage.getItem('video_im_id');
          const file = event.target.files;
          if (file) {
              const formData = new FormData();
              for (let i = 0; i < file.length; i++) {
                  formData.append('file', file[i]);
              }
              uploadFile(formData).then(res=>{
                  if (res.data.code === 1) {
                      imMeetingGroupSendToGroupMassage({
                          group_id: this.video_Group_id,
                          sender_im_id: video_im_id,
                          msg_type: 'image',
                          file_name:  res.data.data.name,
                          content: res.data.data.path,
                      }).then(res=>{
                          if(res.data.code === '1'){
                              console.log(res)
                              this.send_input = ''
                              // imMeetingGroupGetMsgList({
                              //     group_id: this.video_Group_id,
                              // }).then(res2=>{
                              //     if(res2.data.code === '1'){
                              //         this.video_im_info = res2.data.data.data
                              //         this.scrollToBottom()
                              //     }
                              // })
                          }else{
                              this.$message({
                                  message: res.data.msg,
                                  type: 'warning'
                              });
                          }
                      })
                  } else {
                      this.$message({
                          message: res.data.msg,
                          type: 'warning'
                      });
                  }
              })
          }
      },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, sdkSecretKey, roomId } = this;
        // const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const inviteUserId = this.userId+'_'+localStorage.getItem('user_id')+'_'+`${parseInt(Math.random() * 100000000, 10)}`;

      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, sdkSecretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
      //获取自己的以及对方的im_id
      init_im(){
          chatDinding({
              user_id:this.user_id,
              role:this.user_role,
              client_id:this.client_id,
          }).then(res=>{
              if(res.data.code === "1"){
                  this.user_im_id = res.data.data.im_id
                  localStorage.setItem('video_im_id', res.data.data.im_id);
                  console.log(this.user_im_id)
              }else{
                  this.$message({
                      message: res.data.msg,
                      type: 'warning'
                  });
              }
          })
      },
    async handleEnterRoom() {
        const user_id = localStorage.getItem('user_id');
        const user_role = localStorage.getItem('user_role');
        const roomId = localStorage.getItem('roomid');
        console.log(this.client_id,'sssssssid1')
        let role = ''
        if (user_role == 'user'){
            role = 1
        }else{
            role = 2
        }
        joinRoom({
            id: roomId,
            user_id: user_id,
            role: role
        }).then(res=>{})
        this.meeting_recordsShow_init()
       if (this.isHostMode) {
        if (!this.sdkAppId || !this.sdkSecretKey) {
          alert('请输入sdkAppId和sdkSecretKey');
          return;
        }
        console.log(this.userId,roomId,'啦啦啦')

        if (!this.userId || !roomId) {
          alert('请输入userId和roomId');
          return;
        }
           imMeetingGroupShow({
               meeting_records_id: roomId
           }).then(res=>{
               if(res.data.code === '1'){
                   this.video_Group_id = res.data.data.id
                   console.log(this.client_id,this.video_Group_id,'sssssssid2')
                   imMeetingGroupJoinGroup({
                       im_id:this.user_im_id,
                       group_id:res.data.data.id,
                   }).then(res1=>{
                       if(res1.data.code === '1'){
                           imMeetingGroupGetMsgList({
                               group_id: res.data.data.id,
                           }).then(res2=>{
                               if(res2.data.code === '1'){
                                   this.video_im_info = res2.data.data.data
                                   this.scrollToBottom()
                               }
                           })
                       }
                   })
               } else {
                   this.$message({
                       message: '网络错误',
                       type: 'warning'
                   });
               }
           })
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.sdkSecretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
        const cache_ids = localStorage.getItem('cache_ids');
        if(localStorage.getItem('is_type') !== 1){
            addUserMeetingRecordsList({
                cache_ids: cache_ids,
                user_id: this.userId,
                user_sig: this.userSig,
                meeting_records_id: roomId,
                type: 1,
            }).then(res=>{
                localStorage.removeItem('cache_ids');
            })
            recordsEdit({
                id: roomId,
                user_id: this.userId,
                user_sig: this.userSig,
                organizer_id: this.userId,
            }).then(res=>{})

            startRecording({
                id: roomId,
                organizer_id: user_id,
            }).then(res=>{})

            localStorage.removeItem('is_type')
        }
        console.log(this.remoteUsersViews,this.meeting_recordsShow_list_arr,'看看看')
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !roomId) {
          alert('请重新获取邀请链接');
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.enterRoom();
      this.handleStartLocalAudio();
      this.handleStartLocalVideo();
      this.generateInviteLink();
    },

    async handleExit() {
        this.is_exit = 2
        this.meeting_recordsShow_list = []
        this.video_im_info = []
        const user_id = localStorage.getItem('user_id');
        const user_role = localStorage.getItem('user_role');
        let role = ''
        if (user_role == 'user'){
            role = 1
        }else{
            role = 2
        }
        const roomId = localStorage.getItem('roomid');
        exitRoom({
            id: roomId,
            user_id: user_id,
            role: role
        }).then(res=>{})
        endRecording({
            id: roomId,
            organizer_id: user_id,
        }).then(res=>{})
      await this.exitRoom();
    },

    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.sdkSecretKey) {
        alert('请输入sdkAppId和sdkSecretKey');
        return;
      }
      this.shareStatus = 'sharing';
      try {
        await this.trtc.startScreenShare();
        this.shareStatus = 'shared';
        this.addSuccessLog('Start share screen success');
      } catch (error) {
        this.shareStatus = 'stopped';
        this.addFailedLog(`Start share error: ${error.message}`);
      }
    },
    async handleStopScreenShare() {
      if (this.shareStatus !== 'shared') {
        this.addFailedLog('The Share is not started');
        return;
      }
      this.shareStatus = 'stopping';
      try {
        await this.trtc.stopScreenShare();
        this.shareStatus = 'stopped';
        this.addSuccessLog('Stop share screen success');
      } catch (error) {
        this.shareStatus = 'shared';
        this.addFailedLog(`Stop share error: ${error.message}`);
      }
    },

    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
        const roomId = localStorage.getItem('roomid');
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
  },
};
</script>
<style>
.con_below_left_below1 .el-input__inner{
    border: none;
    padding-left: 1rem;
    padding-right: 3rem;
}
</style>

<style lang="scss" scoped>
.tool-class {
  color: red; font-size: 12px;
}
.button1{
  background-color: #b6e300;
}
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }

  .remote-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .remote-stream-container {
      width: 320px;
      height: 240px;
      margin: 0 10px 10px 0;
      //border: 2px #0e92dd solid;
      //border-radius:5px;
    }
  }
}
.comment_input_button{
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;
}
.con_below_left_below1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.is_zoom_style{
    width: 99% !important;
    height: 100% !important;
    margin-left: 0 !important;
}
.is_zoom_styles{
    width: 40% !important;
    height: 70% !important;
    position: fixed !important;
    display: block !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0 !important;
    z-index: 9999;
}
</style>
