<template>
    <div class="con">
        <div style="margin-top: 1rem;">
            <span style="padding: 5%;font-size: 20px;font-weight: 400;border-bottom: 2px #0e92dd solid">个人中心</span>
        </div>
        <div style="margin: 0.5rem auto;width: 90%;display: flex;justify-content: space-between;align-items: center;border-bottom: 2px #dddddd solid;">
            <div style="padding: 0.5rem;width: 20rem;font-size: 1.5rem;display: flex;justify-content: space-between;">
                <div>
                    <i class="el-icon-user" style="font-size: 1rem;"></i>
                    <span style="font-size: 1rem; margin-left: 0.5rem;">{{ user_info.name }}</span>
                </div>
                <div style="color: #0e92dd;" @click="isUpdateName = true">
                    <i class="el-icon-edit"></i>
                </div>
            </div>
        </div>

        <div style="margin:0 auto;width: 90%;display: flex;justify-content: space-between;align-items: center;border-bottom: 2px #dddddd solid;">
            <div style="padding: 0.5rem;width: 20rem;font-size: 1.5rem;display: flex;justify-content: space-between;">
                <div>
                    <i class="el-icon-bank-card" style="font-size: 1rem;"></i>
                    <span style="font-size: 1rem; margin-left: 0.5rem;">{{ user_info.id_number }}</span>
                </div>
                <div style="color: #0e92dd;" @click="isUpdateNumber = true">
                    <i class="el-icon-edit"></i>
                </div>
            </div>
        </div>

        <div style="margin:0.5rem auto;width: 90%;display: flex;justify-content: space-between;align-items: center;border-bottom: 2px #dddddd solid;">
            <div style="padding: 0.5rem;width: 20rem;font-size: 1.5rem;display: flex;justify-content: space-between;">
                <div>
                    <i class="el-icon-phone-outline" style="font-size: 1rem;"></i>
                    <span style="font-size: 1rem; margin-left: 0.5rem;">{{ user_info.tel }}</span>
                </div>
                <div style="color: #0e92dd;" @click="isUpdateMobile = true">
                    <i class="el-icon-edit"></i>
                </div>
            </div>
        </div>
        <el-button class="custom-button" style="margin-top: 0.5rem;" type="primary" @click="myProfile">我的资料</el-button>
        <el-button class="custom-button" type="primary" @click="perPro">个人档案</el-button>
        <el-button class="custom-button" type="primary" @click="myConsultation">我的问诊</el-button>
        <el-button class="custom-button" type="primary" @click="myDiagnosis">我的诊疗</el-button>
        <el-button class="custom-button" type="primary" @click="logout">退出登录</el-button>
        <el-dialog title="个人资料" :visible.sync="perfectInfo" width="30%">
            <div style="margin-top: 1%;" id="userinfo">
                <div style="width: 90%;height: 300px;border: 1px #dddddd solid;border-radius: 10px;margin: auto;text-align: left;">
                    <div style="width: 95%;height: 20%;display: flex;align-items: center;justify-content: center;border-bottom: 1px #dddddd solid;margin: auto;">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <span style="width: 40px;font-size:12px;white-space: nowrap;">姓名：</span>
                            <el-input v-model="name" placeholder="请输入姓名" style="width: 60%; height: 70%;padding: 0 3px !important;" size="mini"></el-input>
                        </div>
                        <div style="margin-left:2px;width: 15%;height:70%;display: flex;align-items: center;justify-content: center;">
                            <el-select v-model="value" placeholder="性别" style="width: 80px;" size="mini">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <span style="width: 40px;font-size:12px;white-space: nowrap;">年龄：</span>
                            <el-input v-model="age" placeholder="请输入年龄" style="width: 40%; height: 70%;" size="mini"></el-input>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: center;width: 80%;">
                            <span style="width: 60px;font-size:12px;white-space: nowrap;">身份证：</span>
                            <el-input v-model="up_number" placeholder="请输入年龄" style="width: 80%; height: 70%;" size="mini"></el-input>
                        </div>
                    </div>
                    <div style="margin: 2% 0 2% 2%;text-align: left;">
                        <span style="padding: 1%;font-size: 15px;font-weight: 400;border-left: 2px #0e92dd solid">个人档案信息</span>
                    </div>
                    <div style="height: 200px;overflow-y: auto;">
                        <div style="font-size: 15px;padding: 5px;word-wrap: break-word;overflow-wrap: break-word;" v-for="(item,index) in userFilesList" :key="index">
                            <span>{{ index + 1 }}. </span>
                            <span> {{ item.create_time }} </span>
                            <span> {{ item.disease.name }} </span>
                            <span> <el-button style="width: 12%;font-weight: bold;margin: 3px 0;padding: 3px 0;" type="primary" @click="informationDetail(item.disease)">详情</el-button> </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 95%;height: 150px;margin: auto">
                <div style="width: 100%;height: 100%;display: flex;justify-content: space-evenly;align-items: center;">
                    <div style="width: 26%;height: 85%;" v-for="(item,index) in userAnnexList.slice(0,2)" :key="index">
<!--                        <img style="width: 100%;height: 100%;" :src="item.file_url">-->
                        <el-image
                            style="width: 100%;height: 100%;"
                            :src="item.file_url"
                            :preview-src-list="[item.file_url]">
                        </el-image>
                    </div>
                    <div style="width: 26%;height: 85%;border: 1px #dddddd solid;display: flex;align-items: center;flex-wrap: wrap;justify-content: center;" v-if="userAnnexList.length >= 3">
                        <div style="font-size:15px;font-weight: bolder;width: 100%;text-align: center;" @click="look_more">查看更多</div>
                        <div @click="look_more"><i class="el-icon-plus" style="font-size:40px;font-weight: bolder;"></i></div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;margin: 20px 0;">
                <el-button style="width: 20%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #0e92dd;background-color: #e6f1fc;" @click="updateUser">修改</el-button>
            </div>
        </el-dialog>

        <!--//修改账号名称-->
        <el-dialog title="修改名称" :visible.sync="isUpdateName" width="30%">
            <el-form ref="form">
                <div>
                    <el-form-item class="no-border">
                        <el-input v-model="username" placeholder="请输入修改的名称" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item style="margin: 5% 0;">
                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updateName">确定修改</el-button>
                </el-form-item>
            </el-form>
            <div style="width: 100%;height: 20px;"></div>
        </el-dialog>

        <!--//修改身份证号码-->
        <el-dialog title="修改身份证号码" :visible.sync="isUpdateNumber" width="30%">
            <el-form ref="form">
                <div>
                    <el-form-item class="no-border">
                        <el-input v-model="id_number" placeholder="请输入修改的身份证号码" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item style="margin: 5% 0;">
                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updateNumber">确定修改</el-button>
                </el-form-item>
            </el-form>
            <div style="width: 100%;height: 20px;"></div>
        </el-dialog>

        <!--//修改手机号码-->
        <el-dialog title="修改手机号码" :visible.sync="isUpdateMobile" width="30%">
            <el-form ref="form">
                <div>
                    <el-form-item class="no-border">
                        <el-input v-model="tel" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
<!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
<!--                        <div style="display: flex;justify-content: center">-->
<!--                            <el-input v-model="code" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;"></el-input>-->
<!--                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;" type="primary" @click="startCountdown">{{ codeText }}</el-button>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
                </div>
                <el-form-item style="margin: 5% 0;">
                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updatePhone">确定修改</el-button>
                </el-form-item>
            </el-form>
            <div style="width: 100%;height: 20px;"></div>
        </el-dialog>

        <!--//档案信息详情-->
        <el-dialog title="疾病信息详情" :visible.sync="informationDetails" width="30%">
            <div style="width: 90%;height: 500px;overflow-y: auto;padding: 20px;white-space: normal;margin: auto;" v-html="filesText"></div>
        </el-dialog>

        <!--//查看更多附件-->
        <el-dialog title="查看更多" :visible.sync="lookMore" width="30%">
            <div style="width: 90%;height: 500px;overflow-y: auto;padding: 20px;white-space: normal;margin: auto;">
                <div style="width: 150px;height: 150px;float: left;padding: 10px" v-for="(item,index) in userAnnexList" :key="index">
                    <el-image
                        style="width: 100%;height: 100%;"
                        :src="item.file_url"
                        :preview-src-list="[item.file_url]">
                    </el-image>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {user,userEdit,sendSmS,editTel,medicalRecords,attachment} from "@/api";

export default  {
    name: 'patientLogin',
    props: {
        id_MyProfile: Number,
    },
    data() {
        return {
            perfectInfo: false,
            isUpdateName: false,
            isUpdateNumber: false,
            isUpdateMobile: false,
            informationDetails: false,
            lookMore: false,
            options: [{
                value: '0',
                label: '保密'
            }, {
                value: '1',
                label: '男'
            }, {
                value: '2',
                label: '女'
            }],
            value: '',
            up_number: '',
            name: '',
            age: '',
            user_info:[],
            username:'',
            id_number:'',
            tel: '',
            code: '',
            //定时器
            timer: null,
            count: 60,
            //发送验证码按钮内容
            codeText: '获取验证码',
            codeButType: 1,
            userFilesList: [],
            userAnnexList: [],
            filesText: '',
            user_id: '',
        }
    },
    watch: {
        id_MyProfile(newValue, oldValue) {
            // 处理 prop 变化的逻辑
            console.log('id_MyProfile 的值从', oldValue, '变为', newValue);
        },
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.userInfo()
        this.filesList()
        this.annexList()
    },
    methods: {
        look_more(){
            this.lookMore = true
        },
        //获取验证码
        startCountdown(){
            if(this.codeButType === 1){
                this.codeButType = 2
                sendSmS({
                    tel:this.tel
                }).then(res=>{
                    if(res.data.code === 1){
                        this.timer = setInterval(() => {
                            this.count--;
                            if (this.count <= 0) {
                                clearInterval(this.timer);
                                this.timer = null;
                                this.count = 60;
                                this.codeText = '获取验证码';
                                this.codeButType = 1
                            } else {
                                this.codeText = `${this.count} 秒后重发`;
                            }
                        }, 1000);
                    }else if(res.data.code == 401){
                        this.logout()
                    }else{
                        this.codeButType = 1
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        myProfile(){
            this.perfectInfo = true
            this.filesList()
        },
        perPro(){
            this.$emit('perPro');
        },
        myConsultation(){
            this.$emit('myConsultation');
        },
        myDiagnosis(){
            this.$emit('myDiagnosis');
        },
        logout(){
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_role');
            localStorage.removeItem('en_user_name');
            this.$emit('loginOuts');
        },
        //获取患者信息
        userInfo(){
            user().then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                    this.value = res.data.data.sex
                    this.up_number = res.data.data.id_number
                    this.age = res.data.data.age
                    this.name = res.data.data.name
                }else if(res.data.code == 401){
                    this.logout()
                }
            })
        },
        //修改名字
        updateName(){
            userEdit({
                name: this.username,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.userInfo()
                    this.isUpdateName = false
                    this.username = ''
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //修改身份证号码
        updateNumber(){
            userEdit({
                id_number: this.id_number,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.userInfo()
                    this.isUpdateNumber = false
                    this.id_number = ''
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //修改手机号码
        updatePhone(){
            editTel({
                tel: this.tel,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.userInfo()
                    this.isUpdateMobile = false
                    this.tel = ''
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //修改个人资料
        updateUser(){
            userEdit({
                name: this.name,
                sex: this.value,
                age: this.age,
                id_number: this.up_number,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //获取个人档案列表
        filesList(){
            medicalRecords().then(res=>{
                if(res.data.code === 1){
                    this.userFilesList = res.data.data.data
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //获取患者附件列表
        annexList(){
            attachment({
                user_id: this.user_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.userAnnexList = res.data.data.data
                }else if(res.data.code == 401){
                    this.logout()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        informationDetail(item){
            this.informationDetails = true
            this.filesText = item.intro
        }
    }
}
</script>
<style>
div::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.el-input__inner{
    padding: 0 0.2rem;
}
</style>
<style scoped>
.con{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px #bbbbbb solid;
    text-align: center;
}
.custom-button {
    width: 60%;
    height: 2rem; /* 使用 rem 单位，根据需要调整 */
    font-weight: bold;
    font-size: 1rem;
    padding: 0;
    margin: 0.3rem 0; /* 使用 rem 单位，根据需要调整 */
}
</style>
