<template>
    <div class="con">
        <div style="width: 100%;font-size: 19px;text-align: center;padding-top: 10px;">我的诊疗</div>
        <div style="width: 90%;height: 86%;border-radius: 10px;margin: 1rem auto 3rem; overflow-y: auto;border: 1px #ddd solid;">
            <div style="width: 90%;margin: 0.5rem auto;font-size: 1.3rem;padding: 1rem;border-bottom: 2px #ccc solid;" v-for="(item,index) in diagnosisLists" :key="index">
                <div style="display: flex;justify-content: space-between;">
                    <div>编号: {{item.order_num}}</div>
                    <div>时间: {{item.create_time}}</div>
                </div>
                <div class="text" style="text-align: left;font-size: 16px;">医生: {{ item.doctor.name }} 诊断: <span v-for="(item2,index2) in item.userDiagnose" :key="index2"> {{ item2.describe }}  </span> </div>
                <div class="text" style="text-align: left;font-size: 16px;">{{ item.order_type === '1' ? '诊疗' : '处方' }}: <span v-for="(item1,index1) in item.userPrescription" :key="index1"> {{ item1.prescription.name }}x{{ item1.number }}{{ item1.unit }} </span></div>
                <div>备注: {{item.remark}}</div>
            </div>
        </div>
    </div>
</template>
<script>

import {diagnosisOrder} from "@/api";

export default {
    name: 'diagnosisInfo',
    data() {
        return {
            diagnosisLists: [],
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id')
        this.init()
    },
    methods: {
        init(){
            diagnosisOrder({
                page: 1,
                limit: 999,
                order_type: 0,
                user_id: this.user_id,
            }).then(res1=>{
                if(res1.data.code === 1){
                    this.diagnosisLists = res1.data.data.data
                }else{
                    this.$message({
                        message: res1.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style scoped>
.con {
    height: 95%;
    width: 42%;
    background-color: #f7f7f7;
    border: 1px #d6d8dc solid;
}
</style>
