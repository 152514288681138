<template>
  <div>
    <comp-nav></comp-nav>
    <div class="content" :class="$isMobile && 'content-mobile'">
      <comp-guidance></comp-guidance>
<!--      <p class="label">参数</p>-->
<!--      <div class="param-container" :class="$isMobile && 'param-container-mobile'">-->
<!--        <comp-info-input-->
<!--            label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></comp-info-input>-->
<!--        <comp-info-input-->
<!--            label="sdkSecretKey" @change="handleValueChange($event, 'sdkSecretKey')"></comp-info-input>-->
<!--        <comp-info-input-->
<!--            label="userId" @change="handleValueChange($event, 'userId')"></comp-info-input>-->
<!--        <comp-info-input-->
<!--            label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input>-->
<!--      </div>-->
<!--      <p class="label">设备选择</p>-->
<!--      <div class="param-container" :class="$isMobile && 'param-container-mobile'">-->
<!--        <comp-device-select-->
<!--            deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>-->
<!--        <comp-device-select-->
<!--            deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>-->
<!--      </div>-->
        <comp-room ref="videoims" :sdkAppId="Number(sdkAppId)" :sdkSecretKey="sdkSecretKey" :userId="userId" :roomId="Number(roomId)" :cameraId="cameraId" :microphoneId="microphoneId"></comp-room>
    </div>
  </div>
</template>

<script>
import compNav from '@/components/comp-nav.vue';
import compGuidance from '@/components/comp-guidance.vue';
import compInfoInput from '@/components/comp-info-input.vue';
import compDeviceSelect from '@/components/comp-device-select.vue';
import compRoom from '@/components/comp-rooms.vue';
import { clearUrlParam } from '@/utils/utils';

export default {
  name: 'App',
  components: {
      compRoom,
    compNav,
    compGuidance,
    compInfoInput,
    compDeviceSelect,
  },
  data() {
    return {
      sdkAppId: 1600028455,
      sdkSecretKey: '7086ca5085e21924ff96afc62da8ba0f4ee6c1ab4af34c55523e3f4cca5a5af9',
      userId: '',
      roomId: 0,
      cameraId: '',
      microphoneId: '',
    };
  },
  created() {
      this.userId =  localStorage.getItem('userId')
  },
  methods: {
    handleValueChange(value, key) {
      this[key] = value;
    },
    videodata(e) {
        if (this.$refs.videoims) {
            this.$refs.videoims.videodata(e)
        }
    }
  },
  mounted() {
    clearUrlParam();
      this.roomId = localStorage.getItem('roomid')
      console.log(this.roomId,'房间号2')
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  .content {
    width: 100%;
    margin: 0 auto;
    max-width: 1320px;
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

