import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store/login.js'
import _ from 'lodash'
import router from './router';
import VueCompositionAPI from '@vue/composition-api'
import TRTC from 'trtc-sdk-v5';
import '@/utils/aegis.js';
import { isMobile } from '@/utils/utils';
Vue.use(VueCompositionAPI)

Vue.use(ElementUI)

import Breadcrumb from "@/components/Breadcrumb.vue";
Vue.component('Breadcrumb', Breadcrumb)
// Vue.mixin({
//   methods: {
//     show_home() {
//       this.$emit('show-home');
//     },
//   }
// })


Vue.config.productionTip = false
Vue.prototype._ = _
Vue.prototype.$isMobile = isMobile;
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
