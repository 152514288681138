<template>
    <div id="app">
        <div id="home" ref="homeref">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            Specifications: {
                ww: 1,
                wh: 1,
                width: 1920,
                height: 919,
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const newWW = window.innerWidth / this.Specifications.width;
            const newWH = window.innerHeight / this.Specifications.height;

            // 确保宽度和高度的比例相同
            if (newWW <= newWH) {
                this.Specifications.ww = newWW;
                this.Specifications.wh = newWW;
            } else {
                this.Specifications.ww = newWH;
                this.Specifications.wh = newWH;
            }

            this.$refs.homeref.style.setProperty('--scaleX', this.Specifications.ww);
            this.$refs.homeref.style.setProperty('--scaleY', this.Specifications.wh);
        },
    },
}

</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
//text-align: center;
    color: #2c3e50;
//margin-top: 60px;
    width: 100vw;
    height: 100vh;
}
body{
    margin: 0;
    padding: 0;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #ffffff !important;
}
</style>
