<template>
    <div class="con">
        <div class="image_style" @click="chinese_doctor">
            <div class="image_img"><img src="@/assets/chinese_doctor.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
        </div>
        <div class="image_style" @click="doctor_technology">
            <div class="image_img"><img src="@/assets/doctor_technology.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
        </div>
        <div class="image_style" @click="maternal_child">
            <div class="image_img"><img src="@/assets/maternal_child.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'patientIndex',
    data() {
        return {

        }
    },
    methods: {
        chinese_doctor(){
            this.$emit('choice','chinese_doctor')
        },
        doctor_technology(){
            this.$emit('choice','doctor_technology')
        },
        maternal_child(){
            this.$message({
                message: '开发中',
                type: 'success'
            });
            // this.$emit('choice','maternal_child')
        },
    }
}
</script>
<style scoped>

.con{
    width: 84%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_style{
    width: 250px;
    height: 250px;
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.image_title{
    padding-top: 20px;
    font-size: 25px;
    width: 100%;
    text-align: center;
}

.image_img{
    width: 100%;
    text-align: center;
}

</style>
