<template>
    <div style="width: 100%;">
        <div class="home-title-wrap">
            <div class="home-title-box">
                <div class="logo" style="">
                    <img src="@/assets/myglogo.png" style="width: 35px;height: 35px" alt="Vue Image">
                    <span class="logo_text">名医馆</span>
                </div>
                <div class="userinfo">
                    <div class="quick_admin_login" v-if="admin_flag == 1" @click="adminLogin">
                        <img src="@/assets/computer.png" style="width: 30px;height: 30px; margin-right: 10px;" alt="Vue Image">
                        后台登录
                    </div>
                    <el-popover :width="150" trigger="hover">
                        <template #reference>
                            <div style="cursor: pointer;display: flex;align-items: center;">
                                <img src="@/assets/people.png" style="width: 30px;height: 30px" alt="Vue Image">
                                <span class="userinfo_text">个人中心</span>
                            </div>
                        </template>
                        <div style="width: 100%;">
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="MyIndex">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">首页</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="MyProfile">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">我的资料</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="PersonalProfile">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">个人档案</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="MyConsultation">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">我的问诊</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="MyDiagnosis">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">我的诊疗</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="MyfollowUp">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">我的随访</span>
                            </div>
                            <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"
                                @click="OutLogin">
                                <span
                                    style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;padding: 5px;">退出登录</span>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <div class="menu-router-box">
            <doctor-menu @menuIndex="menuIndex" :defaultType="defaultType"></doctor-menu>
            <div class="menu-router-bg"></div>
        </div>
        <div class="content" style="margin: auto; margin-top: 20px; position: relative;">
            <div class="custom-lt-list" style="height: 100%;" v-if="go_publish_type === 1">
                <div class="title custom-title">
                    <div class="custom-sub-title">
                        科室
                    </div>
                    <el-menu :default-active="activeIndex" class="el-menu-demo" @select="handleSelect"
                        background-color="#F0F9FF" style="width: 100%; height: calc(100% - 74px);">
                        <el-menu-item style="height: 52px;display: flex;align-items: center;font-size: 11px;"
                            class="label" :index="'class-' + index" v-for="(item, index) in classification_list"
                            :key="index">{{ item.name
                            }}</el-menu-item>
                    </el-menu>
                    <div class="custom-bg"></div>
                </div>
                <div class="info custom-list-wrap">
                    <div class="search custom-lt-search">
                        <div class="custom-search-title">
                            论坛
                        </div>
                        <div style="width: 30%;">
                            <el-input style="margin-right: 10%;border-radius: 12px;" placeholder="请输入内容"
                                prefix-icon="el-icon-search" v-model="keyword" @change="forumSearch">
                            </el-input>
                        </div>
                    </div>
                    <div class="custom-sub-box">
                        <div class="add_style custom-add-box" @click="go_publish">
                            <!-- <div class="border_style"></div> -->
                            <div><i class="el-icon-s-promotion"></i></div>
                            <div>去发布</div>
                        </div>
                        <div class="custom-list-box">
                            <div class="info_div" v-for="(forum_item, forum_index) in forum_list" :key="forum_index">
                                <div class="info_div_title" @click="posts_info(forum_item.id)">
                                    {{ forum_item.title }}
                                </div>
                                <div class="info_div_con" @click="posts_info(forum_item.id)">
                                    {{ forum_item.content }}
                                </div>
                                <div class="info_div_image" v-if="forum_item.image.length > 0">
                                    <div class="info_div_image_style"
                                        v-for="(image_item, image_index) in forum_item.image" :key="image_index">
                                        <el-image :src="image_item.url" style="width: 100%;height: 100%" alt="Vue Image"
                                            :preview-src-list="forum_item.image_list"></el-image>
                                    </div>
                                </div>
                                <div style="position: absolute;bottom: 1rem;right: 1rem;color: #bfbfbf;">
                                    <span style="cursor: pointer;" @click="delpost(forum_item.id)"
                                        v-if="forum_item.doctor_id == user_id">删除</span>
                                    <span style="margin-left: 20px;">{{ forum_item.create_time }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="con_below_page">
                            <el-pagination :page-size="20" :pager-count="4" background layout="prev, pager, next"
                                @current-change="file_page" :total="file_total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-two-wrap" v-if="go_publish_type === 2">
                <div class="search custom-lt-search">
                    <div class="custom-search-title">
                        发布论坛
                    </div>
                    <div style="width: 30%;">
                        <el-input style="margin-right: 10%;border-radius: 12px;" placeholder="请输入内容"
                            prefix-icon="el-icon-search" v-model="keyword" @change="forumSearch">
                        </el-input>
                    </div>
                </div>

                <div class="white-wrap">
                    <div class="release_style">
                        <div class="select_style">
                            <el-select v-model="value" placeholder="请选择分类" size="small" @change="classChange">
                                <el-option v-for="item in classification_list" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input_style">
                            <el-input size="small" v-model="input" placeholder="请填写标题"></el-input>
                        </div>
                    </div>
                    <div class="release_style_1">
                        <div class="release_style_title">
                            <div style="width: 20px;height: 20px;margin-right: 5px;" @click="open(3)"><img
                                    src="@/assets/flie.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
                            <div style="width: 20px;height: 20px;margin-right: 5px;" @click="open(1)"><img
                                    src="@/assets/image.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
                            <div style="width: 15px;height: 15px;margin-right: 5px;" @click="open(2)"><img
                                    src="@/assets/video.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
                        </div>
                        <div class="release_style_input">
                            <el-input rows="5" resize="none" type="textarea" placeholder="请输入内容" v-model="textarea"
                                maxlength="1000" show-word-limit>
                            </el-input>
                        </div>
                        <div class="release_style_image">
                            <div class="release_style_images" v-for="(img_item, img_index) in upload_file"
                                :key="img_index">
                                <img :src="img_item.url" style="width: 100%;height: 100%;object-fit: cover;"
                                    alt="Vue Image" v-if="isImageFile(img_item.name)">
                                <img src="@/assets/file.png" style="width: 100%;height: 100%;object-fit: cover;"
                                    alt="Vue Image" v-if="isFile(img_item.name)">
                                <img src="@/assets/video1.png" style="width: 100%;height: 100%;object-fit: cover;"
                                    alt="Vue Image" v-if="isVideoFile(img_item.name)">
                                <img src="@/assets/X.png"
                                    style="width: 15px;height: 15px;position: absolute;top: -8px;right: -8px;"
                                    alt="Vue Image" @click="del_image(img_index)">
                            </div>
                        </div>
                        <div class="release_style_con">
                            <div class="release_style_con_style">附件文件： </div>
                            <div class="release_style_con_style" style="display: flex;align-items: center;"
                                v-for="(img_item, img_index) in upload_file" :key="img_index">
                                <span>{{ img_item.name }}</span>
                                <img src="@/assets/X.png" style="width: 15px;height: 15px;" alt="Vue Image"
                                    @click="del_image(img_index)">
                            </div>
                        </div>
                    </div>
                    <div class="but_style">
                        <el-button type="primary" @click="back">返回</el-button>
                        <el-button type="primary" @click="addForum">去发布</el-button>
                    </div>
                </div>
            </div>
            <div style="height: 100%;" v-if="go_publish_type === 3">
                <div class="custom-lt-list">
                    <div class="title custom-title">
                        <div class="custom-sub-title">
                            科室
                        </div>
                        <el-menu :default-active="activeIndex" class="el-menu-demo" @select="handleSelect"
                            background-color="#F0F9FF" style="height: 100%;">
                            <el-menu-item style="height: 100%;display: flex;align-items: center;font-size: 11px;"
                                class="label" :index="'class-' + index" v-for="(item, index) in classification_list"
                                :key="index">{{ item.name
                                }}</el-menu-item>
                        </el-menu>
                        <div class="custom-bg"></div>
                    </div>
                    <div class="info_one">
                        <div class="search">
                            <div class="custom-search-title">
                                论坛评论
                            </div>
                            <div class="custom-search-right-box">
                                <div style="margin-right: 10px;">
                                    <el-button type="primary" size="small" @click="back">返回</el-button>
                                </div>
                                <div style="width: 281px;">
                                    <el-input style="margin-right: 10%;border-radius: 12px;" placeholder="请输入内容"
                                        prefix-icon="el-icon-search" v-model="keyword" @change="forumSearch">
                                    </el-input>
                                </div>
                            </div>
                        </div>

                        <div class="info_div_one">
                            <div class="add_style custom-add-box" @click="go_publish">
                                <!-- <div class="border_style"></div> -->
                                <div><i class="el-icon-s-promotion"></i></div>
                                <div>去发布</div>
                            </div>
                            <div class="info_div_title_one">
                                <div style="width: 80%;padding: 0.5rem 0">{{ forum_info.title }}</div>
                                <div style="width: 20%;text-align: right;">{{ forum_info.doctor.name }}</div>
                            </div>
                            <div class="info_div_con_one">
                                {{ forum_info.content }}
                            </div>
                            <div class="info_div_image_one" v-if="forum_info.image.length > 0">
                                <div class="info_div_image_style"
                                    v-for="(images_info, images_index) in forum_info.image" :key="images_index">
                                    <img :src="images_info.url" style="width: 100%;height: 100%" alt="Vue Image">
                                </div>
                            </div>
                            <div class="info_div_video_one" v-if="forum_info.video.length > 0">
                                <div style="width: 80%;margin: auto;height: 90%;" v-for="(img, i) in forum_info.video"
                                    :key="i">
                                    <video :src="img.url" controls style="width: 100%;height: 300px;"></video>
                                </div>
                            </div>
                            <div class="info_div_file_one" v-if="forum_info.attachment.length > 0">
                                <div v-for="(img, i) in forum_info.attachment" :key="i">
                                    <a class="file_style" :download="img.name" :href="img.url">{{ img.name }}</a>
                                </div>
                            </div>
                            <div class="comment_one">
                                <div style="color: #0e92dd;padding: 5px 0;">评论</div>
                                <div style="color: #000;padding: 8px 0;border-bottom: 1px #ccc solid;"
                                    v-for="(comment_item, comment_index) in Comment_list" :key="comment_index">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div>
                                            <span style="font-weight: bold;">{{ comment_item.doctor.name }}: </span>
                                            <span style="color: #bfbfbf;">{{ comment_item.comment }}</span>
                                        </div>
                                        <div>
                                            <span style="color: #bfbfbf;">{{ comment_item.create_time }}</span>
                                        </div>
                                    </div>
                                    <div style="display: flex;align-items: center;">
                                        <div v-for="(img, i) in comment_item.image" :key="i"
                                            style="padding: 1rem 1rem 1rem 0">
                                            <el-image style="width: 100px; height: 100px" :src="img.url"
                                                :preview-src-list="comment_item.image_list">
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                                <div style="height: 10px;"></div>
                            </div>
                        </div>
                        <div class="comment_input">
                            <el-input type="textarea" class="custom-input" v-model="com_input"
                                placeholder="输入内容"></el-input>
                            <div class="comment_input_button">
                                <div style="width: 1px;height: 18px;background: #b2afaf;margin: 0 8px;"></div>
                                <div style="width: 20px;height: 20px;margin-right: 5px;" @click="open(4)"><img
                                        src="@/assets/image.png" style="width: 100%;height: 100%" alt="Vue Image"></div>
                                <div style="width: 20px;height: 20px;margin-right: 5px;">
                                    <el-popover placement="bottom" title="请选择表情" :width="300" trigger="click">

                                        <template #reference>
                                            <img src="@/assets/smiling_face.png" style="width: 100%;height: 100%"
                                                alt="Vue Image">
                                        </template>
                                        <chatIcon @changeText="getIconChild" />
                                    </el-popover>
                                </div>
                                <div style="width: 1px;height: 18px;background: #b2afaf;margin: 0 8px;"></div>
                                <div style="width: 22px;height: 22px;background: #017ED8;border-radius: 8px;display: flex;justify-content: center;align-items: center;"
                                    @click="send(forum_info.id)">
                                    <img src="@/assets/send.png" style="width: 18px;height: 18px" alt="Vue Image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 上传图片 -->
            <input type="file" style="display: none;" @change="uploads" accept=".jpg,.png,.JPG,.JPEG,.PNG" ref="Img"
                multiple />
            <!-- 上传视频 -->
            <input type="file" style="display: none;" @change="uploads" accept=".mp4" ref="Video" multiple />
            <!-- 上传文件 -->
            <input type="file" style="display: none;" @change="uploads"
                accept=".word,.xlsx,.doc,.docx,.txt,.PPT,.PDF,.WAR" ref="File" multiple />

        </div>
    </div>
</template>

<script>
import {
    pwdLogin,
    medicalCategory,
    medicalForum,
    uploadFile,
    ForumAdd,
    medicalForumsComment,
    medicalShow,
    medicalForumsCommentList, carouselImages, medicalForumDel
} from "@/api";
import { Upload_Api_url } from '@/api/index.js';
import chatIcon from '../components/chatIcon.vue'
import doctorMenu from '@/components/doctorMenu.vue'

export default {
    name: 'forumList',
    components: {
        chatIcon,
        doctorMenu,
    },
    data() {
        return {
            carouselHeight: '400px',
            com_input: '',
            textarea: '',
            keyword: '',
            activeIndex: 'class-0',
            go_publish_type: 1,
            value: '',
            input: '',
            classification_list: [],
            forum_list: [],
            class_id: '',
            forum_info: [],
            typeFile: 1,
            Img: null,   // 上传图片
            Video: null,   // 上传视频
            File: null,   // 上传文件
            Upload_Api_urls: '',
            token: '',
            upload_file: [],
            upload_type: 1,
            image_array: [],
            video_array: [],
            file_array: [],
            user_id: [],
            //科室id
            classChange_id: '',
            forum_info_id: '',
            Comment_list: [],
            comm_array: [],
            images: [],
            page: 1,
            file_total: 0,
            menuIndex: 6,
        }
    },
    mounted() {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id')
        this.Upload_Api_urls = Upload_Api_url
        this.forum_classification()
        this.updateCarouselHeight();
        this.Carousel()
    },
    methods: {
        MyProfile() {
            this.$router.push('/');
        },
        PersonalProfile() {
            this.$router.push('/');
        },
        MyConsultation() {
            this.$router.push('/');
        },
        MyDiagnosis() {
            this.$router.push('/');
        },
        MyfollowUp() {
            this.$router.push('/');
        },
        OutLogin() {
            this.$router.push('/');
        },
        //用户端回到首页
        MyIndex() {
            this.$router.push('/');
        },
        adminLogin() {
            pwdLogin({
                tel: localStorage.getItem("tel"),
                password: localStorage.getItem("password"),
                flag: localStorage.getItem("admin_flag"),
                role: 'doctor',
                doctorId: localStorage.getItem("user_id")
            }).then(res => {
                console.log(res)
                if (res.data.code === 1) {
                    console.log(res, 'reLogin')
                    const token = res.data.data.token;
                    const userinfo = res.data.data.userinfo;
                    window.open(Back_Web_Url + '/admin/#/login?test=1&token=' + token, '_blank')
                    // this.tels = ''
                    // this.passWord = ''
                    // localStorage.setItem('token', res.data.data.token);
                    // localStorage.setItem('user_id', res.data.data.user_id);
                    // localStorage.setItem('user_role', res.data.data.user_role);
                    // localStorage.setItem('en_user_name', res.data.data.en_name);
                    // localStorage.setItem('medical_department_id', res.data.data.medical_department_id);
                    // localStorage.setItem('admin_flag', res.data.data.admin_flag);
                    // localStorage.setItem('tel', this.tels);
                    // localStorage.setItem('password', this.passWord);
                    // this.init(res.data.data.user_id,res.data.data.en_name,res.data.data.user_role)
                    //
                    // console.log(Invitation_type,'啦啦啦')
                    // if(Invitation_type == 2){
                    //   localStorage.setItem('Invitation_type',1)
                    //   this.$emit('backInvitation_type');
                    // }else{
                    //   localStorage.setItem('Invitation_type',1)
                    //   this.$emit('loginUser');
                    // }

                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //删除帖子
        delpost(id) {
            console.log(id)
            medicalForumDel({
                id: id
            }).then(res => {
                if (res.data.code === 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.list(this.class_id)
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        carousel(url) {
            if (url !== '') {
                window.open(url, '_blank')
            }
        },
        Carousel() {
            this.images = []
            carouselImages().then(res => {
                if (res.data.code === 1) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].is_show === '1') {
                            const array = {
                                'image': res.data.data[i].image_url,
                                'url': res.data.data[i].links,
                            }
                            this.images.push(array)
                        }
                    }
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        updateCarouselHeight() {
            this.carouselHeight = window.innerHeight / 3 + 'px';
        },
        //选择表情
        getIconChild(e) {
            this.com_input += e
        },
        //发送评论
        send(id) {
            medicalForumsComment({
                medical_forums_id: id,
                doctor_id: this.user_id,
                comment: this.com_input,
                image: this.comm_array
            }).then(res => {
                if (res.data.code === 1) {
                    this.$message({
                        message: '评论成功',
                        type: 'success'
                    });
                    this.com_input = ''
                    this.comm_array = []
                    this.comment_info(this.forum_info_id)
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        // 检查文件名是否包含图片扩展名
        isImageFile(filename) {
            return /\.(jpg|JPG|png|PNG|jpeg|JPEG|mp3|MP3)$/i.test(filename);
        },
        // 检查文件名是否包含图片扩展名
        isVideoFile(filename) {
            return /\.(mp4)$/i.test(filename);
        },
        // 检查文件名是否包含图片扩展名
        isFile(filename) {
            return /\.(word|xlsx|doc|docx|txt|PPT|PDF|WAR)$/i.test(filename);
        },
        classChange(e) {
            this.classChange_id = e
        },
        //发布论坛
        addForum() {
            console.log(this.classChange_id, this.input, this.textarea, this.image_array, this.video_array, this.file_array, this.user_id)
            ForumAdd({
                medical_category_id: this.classChange_id,
                doctor_id: this.user_id,
                title: this.input,
                content: this.textarea,
                attachment: this.file_array,
                image: this.image_array,
                video: this.video_array,
            }).then(res => {
                if (res.data.code === 1) {
                    this.list(this.classChange_id)
                    this.$message({
                        message: '发布成功',
                        type: 'success'
                    });
                    this.value = ''
                    this.go_publish_type = 1
                    this.classChange_id = ''
                    this.input = ''
                    this.textarea = ''
                    this.file_array = []
                    this.image_array = []
                    this.video_array = []
                    this.upload_file = []
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        handleSelect(key) {
            this.go_publish_type = 1
            const id = key.split('-').pop()
            const ids = this.classification_list[id].id
            this.class_id = ids
            this.list(ids)
        },
        //列表分页
        file_page(e) {
            this.page = e
            this.list(this.class_id)
        },
        //论坛列表
        list(ids) {
            medicalForum({
                page: this.page,
                limit: 20,
                medical_category_id: ids,
                title: this.keyword
            }).then(res => {
                if (res.data.code === 1) {
                    this.forum_list = res.data.data.data
                    this.file_total = Number(res.data.data.total)
                } else {
                    this.$message({
                        message: '获取论坛失败',
                        type: 'error'
                    });
                }
            })
        },
        //搜搜关键字
        forumSearch(e) {
            this.go_publish_type = 1
            console.log(e)
            this.keyword = e
            this.list(this.class_id)
        },
        go_publish() {
            this.go_publish_type = 2
        },
        back() {
            this.go_publish_type = 1
        },
        //评论详情
        comment_info(id) {
            medicalForumsCommentList({
                page: 1,
                limit: 999,
                medical_forums_id: id
            }).then(res => {
                if (res.data.code === 1) {
                    this.Comment_list = res.data.data.data
                } else {
                    this.$message({
                        message: '网络错误',
                        type: 'error'
                    });
                }
            })
        },
        //论坛详情
        posts_info(item) {
            this.forum_info_id = item
            medicalShow({
                id: item
            }).then(res => {
                if (res.data.code === 1) {
                    this.forum_info = res.data.data
                    this.go_publish_type = 3
                } else {
                    this.$message({
                        message: '获取论坛失败',
                        type: 'error'
                    });
                }
            })
            this.comment_info(item)
        },
        forum_classification() {
            medicalCategory().then(res => {
                console.log(res)
                if (res.data.code === 1) {
                    this.classification_list = res.data.data
                    this.list(res.data.data[0].id)
                    this.class_id = res.data.data[0].id
                } else {
                    this.$message({
                        message: '获取论坛分类失败',
                        type: 'error'
                    });
                }
            })
        },
        //图片上传选择文件
        open(type) {
            const typeFile = this.$refs['File'];
            this.upload_type = type
            switch (type) {
                case 1:
                    this.$refs.Img.click();
                    break;
                case 2:
                    this.$refs.Video.click();
                    break;
                case 3:
                    typeFile.click();
                    break;
                case 4:
                    this.$refs.Img.click();
                    break;
            }
        },
        uploads(event) {
            const file = event.target.files;
            if (file) {
                const formData = new FormData();
                for (let i = 0; i < file.length; i++) {
                    formData.append('file', file[i]);
                }
                uploadFile(formData).then(res => {
                    if (res.data.code === 1) {
                        this.upload_file.push(res.data.data)
                        if (this.upload_type === 1) {
                            this.image_array.push(res.data.data)
                        } else if (this.upload_type === 2) {
                            this.video_array.push(res.data.data)
                        } else if (this.upload_type === 3) {
                            this.file_array.push(res.data.data)
                        } else if (this.upload_type === 4) {
                            this.comm_array.push(res.data.data)
                        }
                        console.log(this.upload_file)
                        this.$message({
                            message: '上传成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        //删除数组
        del_image(item) {
            this.upload_file.splice(item, 1)
        }
    }
}
</script>

<style>
.search .el-input__inner {
    background-color: #E2EEF6;
}

.input_style .el-input__inner {
    background-color: #E2EEF6;
}

.select_style .el-input__inner {
    background-color: #E2EEF6;
}

.release_style_input .el-textarea__inner {
    color: #E2EEF6;
    font-size: 15px;
}

.comment_input .el-input__inner {
    background-color: #E2EEF6;
    padding-right: 110px;
}
</style>

<style scoped>
.content {
    min-height: 66vh;
    width: 60%;
    background-color: transparent;
    border: 1px #d6d8dc solid;
}

.title {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    background-color: #ededed;
}

.label {
    height: 40px;
    line-height: 40px;
}

.add_style {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 15px;
    color: #0e92dd;
    cursor: pointer;
}

.custom-add-box {
    justify-content: flex-end;
    height: 74px;
    line-height: 74px;
}

.border_style {
    height: 20px;
    width: 2px;
    background-color: #e4e4e4;
    margin-right: 1rem;
}

.search {
    width: 95%;
    height: 10%;
    margin: 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info {
    width: 100%;
    height: 896px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #F0F9FF;
}

.info_div {
    width: 95%;
    margin: 0 auto 1rem;
    border: 1px #ccc solid;
    background-color: #ffffff;
    position: relative;
}

.info_div_title {
    padding: 1rem 0;
    width: 95%;
    height: 18%;
    margin: auto;
    border-bottom: 1px #ccc solid;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.info_div_con {
    width: 95%;
    height: 18%;
    margin: 0.2rem auto 3rem;
    color: #bfbfbf;
    font-size: 13px;
    padding: 1rem 2px 2px 2px;
    overflow: hidden;
    /* 隐藏溢出的内容 */
    white-space: normal;
    /* 正常换行 */
    text-overflow: ellipsis;
    /* 显示省略号 */
    display: -webkit-box;
    /* 使用弹性盒子模型 */
    -webkit-line-clamp: 2;
    /* 限制两行文本 */
    -webkit-box-orient: vertical;
    /* 设置伸缩容器的子元素排列方式为垂直 */
}

.info_div_image {
    width: 95%;
    height: 50%;
    margin: auto;
    color: #bfbfbf;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding-bottom: 1%;
}

.info_div_image_style {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.info_one {
    width: 100%;
    height: 80%;
}

.info_div_one {
    width: 95%;
    height: 88%;
    margin: 0 auto 1rem;
    border: 1px #ccc solid;
    background-color: #ffffff;
    position: relative;
    overflow-y: auto;
}

.info_div_title_one {
    width: 95%;
    margin: auto;
    border-bottom: 1px #ccc solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.info_div_con_one {
    width: 95%;
    margin: 0.2rem auto;
    color: #bfbfbf;
    font-size: 15px;
    padding: 2px;
    word-break: break-all;
}

.info_div_image_one {
    width: 95%;
    height: 100px;
    margin: auto;
    color: #bfbfbf;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding-bottom: 1%;
}

.info_div_video_one {
    width: 95%;
    margin: auto;
    padding-bottom: 1%;
}

.info_div_file_one {
    width: 95%;
    margin: auto;
    padding-bottom: 1%;
    border-bottom: 1px #ccc solid;
}

.comment_one {
    width: 95%;
    margin: 10px auto;
}

.input_style {
    margin-left: 16px;
    width: 70%;
}

.select_style {
    width: 220px;
}

.release_style {
    width: 95%;
    height: 10%;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.release_style_1 {
    width: 100%;
    height: 80%;
    margin: 15px auto;
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
}

.release_style_title {
    width: 95%;
    margin: auto;
    border-bottom: 1px #ccc solid;
    height: 8%;
    display: flex;
    align-items: center;
}

.release_style_input {
    width: 95%;
    height: 40%;
    margin: 5px auto;
}

.release_style_image {
    width: 95%;
    margin: auto;
    display: flex;
}

.release_style_images {
    width: 15%;
    height: 6rem;
    margin-right: 20px;
    object-fit: cover;
    position: relative;
}

.release_style_con {
    width: 95%;
    height: 20%;
    margin: 5px auto;
    overflow-y: auto;
}

.release_style_con_style {
    padding: 2px 0;
    position: relative;
}

.but_style {
    width: 95%;
    height: 10%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment_input {
    width: 95%;
    height: 15%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
}

.comment_input_button {
    display: flex;
    align-items: center;
    top: 5px;
    position: absolute;
    right: 5px;
}

.file_style {
    text-decoration: none;
    color: #26c3dd;
    cursor: pointer;
}

.carousel {
    width: 100%;
    height: 35%;
    z-index: 1;
    object-fit: cover;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.full-width-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.con_below_page {
    width: 95%;
    height: 10%;
    margin: 0.5% auto 0;
    display: flex;
    justify-content: center;
}

.custom-lt-list {
    display: flex;
    background: transparent;
}

.custom-title {
    margin-right: 20px;
    display: block;
    position: relative;
    width: 200px;
    min-height: 928px;
    height: 100%;
    background-color: #F0F9FF;
}

.custom-sub-title {
    padding-left: 20px;
    height: 74px;
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 74px;
    text-align: left;
    font-style: normal;
}

.custom-lt-search {
    height: 80px !important;
}

.custom-search-title {
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 24px;
    text-align: left;
    font-style: normal;
}

.custom-list-wrap {
    padding: 16px;
}

.custom-bg {
    z-index: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 306px;
    background-image: url('../assets/image/safe.png');
    background-size: 100% 100%;
}

.custom-sub-box {
    background-color: #fff;
}

.custom-list-box {
    height: 680px;
    overflow-y: auto;
    overflow-x: hidden;
}

.menu-router-box {
    position: relative;
}

.menu-router-bg {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 306px;
    background-image: url('../assets/image/top-bg.png');
    background-size: 100% 100%;
}

.home-title-wrap {
    display: flex;
    justify-content: center;
}

.home-title-box {
    width: 1500px;
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 65px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
}

.logo_text {
    color: #0e92dd;
    font-size: 20px;
    font-weight: 500;
}

.userinfo {
    height: 65px;
    width: 302px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 100px;
}

.userinfo .quick_admin_login {
    width: 170px;
    margin-right: 20px;
    color: #333;
    font-size: 18px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.userinfo_text {
    color: #333;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.custom-search-right-box {
    display: flex;
}

.custom-input {
    height: 115px;
}

.custom-input ::v-deep textarea {
    height: 115px;
    background-color: #F0F9FF;
}

.custom-two-wrap {
    position: relative;
    height: 800px;
    background-color: #F0F9FF;
}

.white-wrap {
    height: 600px;
    background-color: #fff;
    margin: 16px;
    padding: 40px 50px;
}
</style>
