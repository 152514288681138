<template>
    <div>
        <div style="width: 90%;height: 400px;margin: auto;border: 1px #dddddd solid;border-radius: 10px;">
            <div class="chat-container" ref="chatcontainer">
                <div v-for="(item,index) in chat_list" :key="index">
                    <div class="chat-item" v-bind:class="{'left': item.role === 'doctor', 'right': item.role === 'user'  }">
                        <div style="display:flex;align-items:center;" v-if="item.role === 'doctor' && item.msg_type === 'text'">
                            <img :src="doctor_pic" alt="头像" class="avatar">
                            <div class="message message-left" style="margin-left: 10px;">{{ item.content }}</div>
                        </div>
                        <div style="display:flex;align-items:center;" v-if="item.role === 'user' && item.msg_type === 'text'">
                            <div class="message message-right" style="margin-right: 10px;">{{ item.content }}</div>
                            <img :src="user_pic" alt="头像" class="avatar">
                        </div>

                        <div style="display:flex;align-items:center;" v-if="item.role === 'doctor' && item.msg_type === 'image'">
                            <img :src="doctor_pic" alt="头像" class="avatar">
                            <div class="message message-left" style="margin-left: 10px;">
                                <img :src="item.content" alt="图片" style="max-width: 100%;">
                            </div>
                        </div>
                        <div style="display:flex;align-items:center;" v-if="item.role === 'user' && item.msg_type === 'image'">
                            <div class="message message-right" style="margin-right: 10px;">
                                <img :src="item.content" alt="图片" style="max-width: 100%;">
                            </div>
                            <img :src="user_pic" alt="头像" class="avatar">
                        </div>
                    </div>
                    <div class="chat-item" v-bind:class="{'centers': item.content === '问诊已结束,对方已下线'}">
                        <div style="display:flex;align-items:center;" v-if="item.msg_type === 'text' && item.content === '问诊已结束,对方已下线'">
                            <div class="message" style="color: #cccccc">-- 以下是新消息 --</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="im_sending" style="position: relative;" v-if="order_states === '2'">
                <el-input v-model="input" placeholder="请输入内容" style="width: 100%;" size="mini"></el-input>
                <label for="upload-btn" class="upload-btn">
                    <img src="@/assets/uploadimg.png" alt="图片" style="max-width: 20px;position: absolute;top: 0.3rem;right: 5rem">
                </label>
                <input type="file" id="upload-btn" style="display: none;" ref="fileInput" @change="handleFileUpload">
                <el-button size="mini" style="position: absolute;top: 0;right: 0.1rem" type="primary" @click="sending">发送</el-button>
            </div>
        </div>
        <div style="width: 100%;height: 100px;margin: 30px auto;display: flex;justify-content: center;align-items: center;background-color: #dddddd;">
            <el-button size="small" type="primary" @click="supplement" v-if="order_states == 2">补充资料</el-button>
            <el-button size="small" type="primary" @click="endConsultation" v-if="order_states == 2">结束问诊</el-button>
        </div>

        <!--//上传资料-->
        <el-dialog title="上传资料" :visible.sync="uploadData" width="30%" :modal="false">
            <div style="width: 90%;height: 14rem;border-radius: 10px;border: #cccccc 1px solid;margin: auto;">
                <div style="width: 95%;height: 2.5rem;display: flex;padding: 0.5rem">
                    <el-input
                        placeholder="请输入患者姓名"
                        v-model="userName"
                        style="width: 10rem"
                        size="mini">
                    </el-input>
                    <el-select v-model="valueSex" placeholder="性别" style="width: 6rem;margin-left: 1rem;" size="mini">
                        <el-option
                            v-for="item in optionsSex"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input
                        placeholder="请输入患者年龄"
                        v-model="userAge"
                        style="width: 10rem;margin-left: 1rem;"
                        size="mini">
                    </el-input>
                </div>
                <div style="width: 90%;height: 1px;background-color: #cccccc;margin: auto;"></div>
                <div style="width: 100%;height: 8.2rem;margin-top: 1px;">
                    <el-input
                        type="textarea"
                        placeholder="请详细描述患者的病情医技既往病史"
                        v-model="textarea"
                        :style="{ width: '100%', fontSize: '1rem' }"
                        resize="none"
                        class="no-border"
                        :rows="4">
                    </el-input>
                </div>
            </div>

            <div style="font-size: 1rem;padding: 0.5rem 0.5rem 0.5rem 1.5rem;">请上传您的病例以及检查结果(最多上传7张)</div>

            <div style="width: 90%;height: 12rem;margin: auto;padding: 1rem">
                <el-upload
                    ref="upload"
                    :action="Upload_Api_urls"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :on-exceed="handleExceed"
                    :limit="7"
                    :headers="{ token: this.token }">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </div>

            <div style="width: 20%;margin: auto">
                <el-button size="small" type="primary" style="width: 100%;margin: 2rem 0;" @click="button">完成</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    attachmentAdd,
    chatDinding,
    getChatRecordsShow,
    getReceiverImId,
    orderEdit,
    sendMassage,
    uploadFile, user,
    userEdit
} from "@/api";
import { Upload_Api_url } from '@/api/index.js';

export default {
    name: 'userIm',
    props: {
        doctor_id: Number,
        order_id: Number,
        doctor_pic: String,
        user_pic: String,
        order_states: String
    },
    data() {
        return {
            Upload_Api_urls: '',
            input: '',
            user_id: '',
            user_role: '',
            client_id: '',
            user_im_id: '',
            doctor_im_id: '',
            chat_list: [],
            chat_msg_id: '',
            uploadData: false,
            userName: '',
            valueSex: '',
            optionsSex: [{
                value: '1',
                label: '男'
            },{
                value: '2',
                label: '女'
            }],
            userAge: '',
            textarea: '',
            token: '',
            dialogVisible: false,
            dialogImageUrl: '',
            upload_image: [],
            upload_image_text: [],
        }
    },
    mounted() {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id');
        this.user_role = localStorage.getItem('user_role');
        this.client_id = localStorage.getItem('client_id');
        this.init()
        this.chatHistory()
        this.userInfo()
        this.Upload_Api_urls = Upload_Api_url
    },
    methods: {
        supplement(){
            this.uploadData = true
        },
        //结束问诊
        endConsultation(){
            orderEdit({
                order_id: this.order_id,
                user_id: this.user_id,
                order_state: 5,
            }).then(res=>{
                if (res.data.code === 1) {
                    sendMassage({
                        role: this.user_role,
                        order_id: this.order_id,
                        msg_type: 'text',
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        content: '问诊已结束,对方已下线',
                    }).then(res => {
                        if (res.data.code === "1") {
                            let array = {
                                id: res.data.data.chat_msg_id,
                                order_id: this.order_id,
                                role: this.user_role,
                                sender_im_id: this.user_im_id,
                                receiver_im_id: this.doctor_im_id,
                                msg_type: "text",
                                content: this.input,
                                create_time: '0',
                                update_time: '0',
                                delete_time: '0',
                            }
                            this.chat_list.push(array)
                            this.input = ''
                            this.scrollToBottom()
                            this.$message({
                                message: '问诊已结束',
                                type: 'success'
                            });
                            this.$emit('Consultation');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //图片选择
        handleFileUpload(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            this.uploadFiles(formData);
        },
        //图片上传
        uploadFiles(formData) {
            console.log(formData,'234')
            uploadFile(formData).then(res=>{
                if(res.data.code === 1){
                    sendMassage({
                        role: 'user',
                        order_id: this.order_id,
                        msg_type: 'image',
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        content: res.data.data.file_path,
                    }).then(res1=>{
                        if(res1.data.code === "1"){
                            let array = {
                                id: res1.data.data.chat_msg_id,
                                order_id: this.order_id,
                                role: "user",
                                sender_im_id: this.user_im_id,
                                receiver_im_id: this.doctor_im_id,
                                msg_type: "image",
                                content: res.data.data.url,
                                create_time: '0',
                                update_time: '0',
                                delete_time: '0',
                            }
                            this.chat_list.push(array)
                            this.scrollToBottom()
                            console.log(this.chat_list,'上传')
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },

        //获取自己的以及对方的im_id
        init(){
            chatDinding({
                user_id:this.user_id,
                role:this.user_role,
                client_id:this.client_id,
            }).then(res=>{
                if(res.data.code === "1"){
                    localStorage.setItem('im_id',res.data.data.im_id);
                    this.user_im_id = res.data.data.im_id
                    getReceiverImId({
                        user_id: this.doctor_id,
                        role: 'doctor',
                    }).then(res=>{
                        if(res.data.code === "1"){
                            this.doctor_im_id = res.data.data.im_id
                            this.dialogMaterial = true
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //发送消息
        sending(){
            sendMassage({
                role: 'user',
                order_id: this.order_id,
                msg_type: 'text',
                sender_im_id: this.user_im_id,
                receiver_im_id: this.doctor_im_id,
                content: this.input,
            }).then(res=>{
                if(res.data.code === "1"){
                    let array = {
                        id: res.data.data.chat_msg_id,
                        order_id: this.order_id,
                        role: "user",
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        msg_type: "text",
                        content: this.input,
                        create_time: '0',
                        update_time: '0',
                        delete_time: '0',
                    }
                    this.chat_list.push(array)
                    this.scrollToBottom()
                    this.input = ''
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取聊天记录
        chatHistory(){
            getChatRecordsShow({
                page: 1,
                limit: 200,
                sender_user_id: this.user_id,
                sender_role: 'user',
                receiver_user_id: this.doctor_id,
                chat_msg_id: '',
            }).then(res=>{
                if(res.data.code === "1"){
                    this.chat_list = res.data.data.data
                    this.scrollToBottom()
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        wssetdata(chat) {
            this.chat_list.push(chat)
            console.log('doctorim', chat)
            this.scrollToBottom()
        },
        //     设置滚动条
        scrollToBottom() {
            this.$nextTick(() => {
                // const scrollContainer = this.$refs.chatcontainer;
                // let height = this.chat_list.length * 55;
                // if (scrollContainer && height) {
                //     scrollContainer.scrollTop = height;
                // }
                const scrollContainer = this.$refs.chatcontainer;
                console.log(scrollContainer.scrollTop,scrollContainer.scrollHeight)
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            });
        },
        //查询患者信息
        userInfo(){
            user().then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                    this.userName = res.data.data.name
                    this.valueSex = res.data.data.sex
                    this.userAge = res.data.data.age
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //图片上传
        handleRemove(file, fileList) {
            console.log(file, fileList ,'删除');
            this.upload_image = this.upload_image.filter(fileName => fileName !== file.response.data.file_path);
            this.upload_image_text = this.upload_image_text.filter(fileName => fileName !== file.response.data.name);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            console.log(file.url,'文件路径')
            this.dialogVisible = true;
        },
        handleSuccess(e){
            this.upload_image.push(e.data['file_path'])
            this.upload_image_text.push(e.data['name'])
        },
        beforeUpload(file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            // const isWord = file.name.endsWith('.doc') || file.name.endsWith('.docx') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf');
            if (!isImage) {
                // if (!isWord) {
                this.$message.error('只能上传图片文件');
                return false;
            }
            const maxSize = 2 * 1024 * 1024; // 2Mb
            if (file.size > maxSize) {
                this.$message.error('文件大小不能超过2Mb');
                return false;
            }
            return true;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        //完成
        button(){
            // orderAdd({
            //     schedules_id: this.schedules_id,
            //     start_time: this.start_time,
            //     order_type: this.orderType,
            // }).then(res=>{
            //     if(res.data.code === 1){
            //         this.isOrder = false
            //         this.$message({
            //             message: '创建成功去我的问诊查看',
            //             type: 'success'
            //         });
            //         this.order_id = res.data.data.order_id
            //         this.uploadData = true
            //     }else{
            //         this.isOrder = false
            //         this.$message({
            //             message: res.data.msg,
            //             type: 'warning'
            //         });
            //     }
            // })
            userEdit({
                name: this.userName,
                sex: this.valueSex,
                age: this.userAge,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code !== 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: '个人信息修改成功',
                        type: 'success'
                    });
                    orderEdit({
                        order_id: this.order_id,
                        user_id: this.user_id,
                        case_description: this.textarea,
                        doctor_remark: '',
                        order_state: 2,
                    }).then(res=>{
                        if(res.data.code !== 1){
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }else{
                            this.$message({
                                message: '详细描述添加成功',
                                type: 'success'
                            });
                            for (let i = 0; i < this.upload_image.length; i++) {
                                attachmentAdd({
                                    order_id: this.order_id,
                                    file_url: this.upload_image[i],
                                    file_name: this.upload_image_text[i],
                                    user_id: this.user_id,
                                }).then(res=>{
                                    if(res.data.code !== 1){
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'warning'
                                        });
                                    }else{
                                        this.$message({
                                            message: '提交成功',
                                            type: 'success'
                                        });
                                        this.uploadData = false
                                    }
                                })
                            }
                        }
                    })
                }
            })
        },
    }
}
</script>
<style>
#im_sending .el-input--mini .el-input__inner{
    padding-right: 23%;
}
.el-textarea__inner{
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}
</style>
<style scoped>
.left{
    justify-content: left;
}

.centers{
    justify-content: center;
}

.right{
    justify-content: right;
}

.chat-container {
    width: 100%;
    height: 93%;
    overflow-y: scroll;
}
.chat-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
}
.chat-item .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.chat-item .message {
    padding: 8px;
    border-radius: 10px;
    max-width: 200px;
}
.chat-item .message-right {
    background-color: #4CAF50;
    color: white;
    align-self: flex-end;
}
.chat-item .message-left {
    background-color: #f1f1f1;
    color: black;
    align-self: flex-start;
}
.chat-input {
    width: 300px;
}
.send-btn {
    background-image: url("https://cdn-icons-png.flaticon.com/512/2931/2931456.png");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}
.upload-btn {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    border: none;
    cursor: pointer;
}
</style>
