<template>
    <div class="con">
        <div class="title" style="position: relative;">医技 <span style="text-align: right;font-size: 1rem;position: absolute;right: 2rem;cursor: pointer;" @click="go_switch"> 去中医 </span></div>
        <div class="listInfo">
            <div style="width: 45%;overflow-y: auto;" v-for="(item,index) in secondMenuList" :key="index">
                <div class="project">{{ item.name }}</div>
                <div style="display: flex;justify-content: space-between;margin-top: 1rem">
                    <div style="width: 100%;">
                        <div class="doctor" v-for="(item1,index1) in item.children" :key="index1" style="margin-top: 1.5rem">
                            <span style="width: 30%;">{{ item1.name }}</span>
                            <span style="margin-left: 20%;" @click="lookDoctor1">查看排班医生</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getMenuList} from "@/api";

export default {
    name: 'patientMenu',
    data() {
        return {
            secondMenuList:[],
            list:[],
            menu_id: '',
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            getMenuList({
                pid: 0,
            }).then(res=>{
                if(res.data.code === 1){
                    this.list = res.data.data
                    for (let i = 0; i < this.list.length; i++) {
                        if(res.data.data[i].name === "医技"){
                            this.menu_id = res.data.data[i].id
                        }
                    }
                    this.secondaryMenu()
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        lookDoctor1(){
            this.$emit('lookDoctor1');
        },
        secondaryMenu(){
            getMenuList({
                pid: this.menu_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.secondMenuList = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        go_switch(){
            this.$emit('go_switch',1);
        }
    }
}
</script>
<style scoped>
.con{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 3px #74888d solid;
    border-radius: 15px;
}
.title{
    width: 95%;
    height: 10%;
    background-color: #0e92dd;
    font-size: 17px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.listInfo{
    width: 90%;
    height: 75%;
    margin: 1% auto;
    display: flex;
    justify-content: space-between;
}
.doctor{
    display:flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px #dddddd solid;
    padding: 2%;
    cursor: pointer;
}
.project{
    width: 90%;
    font-size: 15px;
    margin-top: 2%;
    border-left: 2px #0e92dd solid;
    margin-left: 2%;
    text-align: left;
    padding-left: 3%;
}
</style>
