<template>
    <div class="content">
        <div class="title">个人档案</div>
        <div class="counting">
            <div class="counting_table">
                <div style="height: 100%;overflow-y: auto;">
                    <table style="width: 100%;">
                        <thead>
                        <tr style="font-size: 15px">
                            <th style="width: 12%;">序号</th>
                            <th style="width: 35%;">时间</th>
                            <th style="width: 20%;">上传者</th>
                            <th style="width: 20%;">疾病</th>
                            <th style="width: 25%;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="font-size: 14px;" v-for="(item,index) in userFilesList" :key="index">
                            <td style="text-align: center;">{{ index + 1 }}</td>
                            <td style="text-align: center;">{{ item.create_time }}</td>
                            <td style="text-align: center;">{{ item.user.name }}</td>
                            <td style="text-align: center;">{{ item.disease.name }}</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary" @click="informationDetail(item.disease)">查看</el-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="counting_but">
                <el-button style="width: 20%;font-weight: bold;margin: 10px 0;" type="primary" @click="perfectInfoAdd">添加</el-button>
            </div>
        </div>

        <!--//档案信息详情-->
        <el-dialog title="疾病信息详情" :visible.sync="informationDetails" width="30%">
            <div style="width: 90%;height: 500px;overflow-y: auto;padding: 20px;white-space: normal;margin: auto;" v-html="filesText"></div>
        </el-dialog>

        <el-dialog title="个人档案" :visible.sync="perfectInfo" width="40%">
            <!-- //完善个人档案|添加个人档案-->
            <add-files @filesAddComplete="filesAddComplete" v-if="perfectInfo"></add-files>
        </el-dialog>
    </div>
</template>
<script>
import {medicalRecords} from "@/api";
import addFiles from "@/components/addFiles.vue";
export default {
    name: 'personalProfile',
    components: {addFiles},
    data() {
        return {
            perfectInfo: false,
            informationDetails: false,
            filesText: '',
            userFilesList: [],
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.filesList()
    },
    methods: {
        perfectInfoAdd(){
            this.perfectInfo = true
        },
        //获取疾病详情信息
        informationDetail(item){
            this.informationDetails = true
            this.filesText = item.intro
        },
        //获取个人档案列表
        filesList(){
            medicalRecords().then(res=>{
                if(res.data.code === 1){
                    this.userFilesList = res.data.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //完善档案成功回调
        filesAddComplete(){
            this.perfectInfo = false
            this.filesList()
            this.$message({
                message: '添加成功',
                type: 'success'
            });
        }
    }
}
</script>
<style scoped>
.content{
    height: 95%;
    width: 42%;
    background-color: #f7f7f7;
    border: 1px #d6d8dc solid;
}
.title{
    font-size: 16px;
    padding: 2% 0 0 5%;
    font-weight: 600;
    text-align: center;
}
.counting{
    width: 90%;
    height: 70%;
    margin: 3% auto;
    border-radius: 10px;
    border: 2px #dddddd solid;
}
.counting_table{
    height: 75%;
    width: 100%;
}
.counting_but {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
