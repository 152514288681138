<template>
    <div class="con">
        <div class="image_style" :class="type == 1 ? 'active-type' : ''" @click="doctor">
            <div class="image_title">我是医生</div>
            <div class="image_img"><img src="@/assets/doctor.png" style="width: 322px;height: 322px" alt="Vue Image"></div>
        </div>
        <div class="image_style" :class="type == 2 ? 'active-type' : ''" @click="patient">
            <div class="image_title">我是患者</div>
            <div class="image_img"><img src="@/assets/patient.png" style="width: 322px;height: 322px" alt="Vue Image"></div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'notLogged',
    data() {
        return {
            type: 1
        }
    },
    methods: {
        doctor(){
            this.type = 1;
            console.log(
                "`111111111111111111`"
            );
            this.$emit('login_role','doctor')
        },
        patient(){
            this.type = 2;
            console.log(
                "sdgsdgggssgd"
            );
            this.$emit('login_role','patient')
        },
    }
}
</script>
<style scoped>

.con{
    z-index: 3;
    position: relative;
    margin-top: -400px;
    width: 84%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_style{
    width: 444px;
    height: 444px;
    border: 4px #fff solid;
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0px 5px 13px 0px rgba(0,0,0,0.08);
}

.active-type {
    border: 4px #017ED8 solid;
}

.image_title{
    padding-top: 20px;
    font-size: 25px;
    width: 100%;
    text-align: center;
}

.image_img{
    width: 323px;
    height: 323px;
    text-align: center;
    border: 1px dotted #aaa;
}

</style>
