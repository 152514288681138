<template>
    <div class="con">
        <el-row class="tac" style="height: 100%;overflow-y: hidden;" ref="tac">
            <el-col :span="7">
                <div class="banner-box">
                    当前位置：首页<span class="banner-icon">></span>中医
                </div>
            </el-col>
            <el-col :span="17">
                <el-menu
                    :default-active="defaultType"
                    class="el-menu-vertical-demo custom-menu"
                    @select="selectMenu"
                    background-color="#017ED8"
                    text-color="#fff"
                    mode="horizontal"
                    active-text-color="#ffffff">
                    <el-menu-item index="1" class="menu_style">
                        <span slot="title" style="font-size: 16px;">我的资料</span>
                    </el-menu-item>
                    <el-menu-item index="8" class="menu_style">
                        <span slot="title" style="font-size: 16px;">个人档案</span>
                    </el-menu-item>
                    <el-menu-item index="9" class="menu_style">
                        <span slot="title" style="font-size: 16px;">我的问诊</span>
                    </el-menu-item>
                    <el-menu-item index="10" class="menu_style">
                        <span slot="title" style="font-size: 16px;">我的诊疗</span>
                    </el-menu-item>
                    <el-menu-item index="11" class="menu_style">
                        <span slot="title" style="font-size: 16px;">我的随访</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: 'doctorMenu',
    props: {
        defaultType: {
            type: String,
            default: "1"
        }
    },
    data() {
        return {
            tacHeight: 0
        }
    },
    mounted() {
        console.log(String(this.defaultType),'默认')
      this.$nextTick(() => {
        this.tacHeight = this.$refs.tac.$el.offsetHeight - 1
      })
    },
    methods: {
        onSubmit(){

        },
        selectMenu(e) {
            e = String(e)
            this.$emit('menuIndex',e);
        },
    }
}
</script>
<style>

</style>
<style scoped>
.con{
    width: 100%;
    height: 56px;
    border: 1px #d6d8dc solid;
    background-color:#017ED8;
    position: relative;
    z-index: 1;
}
.menu_style{
    /* border-bottom: 1px #d6d8dc solid; */
    height: 56px;
    line-height: 56px;
    cursor: pointer;
}
.tac ::v-deep .el-menu-item.is-active {
    background-color: #15669F !important;
    border-bottom-color: #15669F !important;
}
.banner-box {
    height: 56px;
    line-height: 56px;
    padding-right: 108px;
    text-align: right;
    background-color: #017ED8;
    color: #fff;
    font-size: 16px;
}
.banner-icon {
    margin: 0 5px;
}
</style>
