import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        loginstatus: false,
        // 用户角色patient, doctor, admin
        role: '',
        userinfo: {},
        // 本地存储的token
        token: localStorage.getItem('token') || ''
    },
    mutations: {
        setLoginStatus(state, newStatus) {
            state.loginstatus = newStatus;
        },
        setRole(state, newRole) {
            state.role = newRole;
        },
        setUserinfo(state, newInfo) {
            state.userinfo = newInfo;
        },
        setToken(state, newToken) {
            state.token = newToken;
        }
    },
    actions: {
        updateLoginStatus({commit}, newStatus) {
            commit('setLoginStatus', newStatus);
        },
        updateRole({commit}, newRole) {
            commit('setRole', newRole);
        },
        updateUserinfo({commit}, newInfo) {
            commit('setUserinfo', newInfo);
        },
        updateToken({commit}, newToken) {
            commit('setToken', newToken);
        }
    }
});

export default store;
