<template>
    <div class="content">
        <div class="title">个人档案123123132</div>
        <div class="counting">
            <div class="counting_table">
                <div style="height: 100%;overflow-y: auto;">
                    <table style="width: 100%;">
                        <thead>
                        <tr style="font-size: 15px">
                            <th style="width: 12%;">序号</th>
                            <th style="width: 35%;">时间</th>
                            <th style="width: 20%;">上传者</th>
                            <th style="width: 25%;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">1</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">装三丰</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">2</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">李四</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">3</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">李四</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">4</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">李四</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">5</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">李四</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <td style="text-align: center;">6</td>
                            <td style="text-align: center;">2023.12.10 15:30</td>
                            <td style="text-align: center;">李四</td>
                            <td style="text-align: center;">
                                <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary">查看</el-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="counting_but">
                <el-button style="width: 20%;font-weight: bold;margin: 10px 0;" type="primary">添加</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'myProfile',
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<style scoped>
.content{
    width: 100%;
    height: 100%;
}
.title{
    font-size: 16px;
    padding: 2% 0 0 5%;
    font-weight: 600;
    text-align: center;
}
.counting{
    width: 90%;
    height: 70%;
    margin: 3% auto;
    border-radius: 10px;
    border: 2px #dddddd solid;
}
.counting_table{
    height: 75%;
    width: 100%;
}
.counting_but {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
