<template>
    <div class="content">
        <div class="searchId">
<!--            <div style="padding: 2% 0 2% 2%;width: 25%;">-->
<!--                <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 8px 0;" type="primary">新建</el-button>-->
<!--                <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 8px 0;" type="primary">读卡</el-button>-->
<!--                <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 8px 0;" type="primary">导入</el-button>-->
<!--            </div>-->
            <div style="width: 70%;font-size: 15px;padding: 10px;position: relative;">
                <el-input v-model="keyword" placeholder="请输入患者的身份证号检索" suffix-icon="el-icon-search" style="width: 100%; border: none;border-radius: 50%;"></el-input>
                <div style="padding: 2%;width: 25%;position: absolute;top: 50%;right: -30%;transform: translateY(-50%);">
                    <el-button style="width: 80%;font-weight: bold;margin: 10px 0;padding: 3px 0;" type="primary" @click="retrieval">检索</el-button>
                </div>
            </div>
        </div>
        <div class="condition">
            <div style="width: 50%;height: 100%;">
                <div style="width:100%;height:fit-content;">
                    <div style="width:100%;height:95%;">
                        <div style="height: 20%;display: flex;margin-bottom: 5px;align-items: center;justify-content: center">
                            <div style="margin-left:2px;width: 5rem;height:70%;display: flex;align-items: center;font-size: 0.8rem;">
                                <!--姓名: {{ user_info.name }}-->
                                <el-input v-model="patient_name" placeholder="姓名" size="mini"></el-input>
                            </div>
                            <div style="margin-left:2px;width: 20%;height:70%;display: flex;align-items: center;">
                                <el-select v-model="patient_sex" placeholder="性别" style="width: 6rem" size="mini">
                                    <el-option
                                        v-for="item in optionsSex"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div style="margin-left:2px;width: 3rem;height:70%;display: flex;align-items: center;font-size: 0.8rem;">
                                <!--年龄: {{ user_info.age }}-->
                                <el-input v-model="patient_age" placeholder="年龄" size="mini"></el-input>
                            </div>
                            <div style="margin-left:2px;margin-right:2px;width: 12rem;height:70%;display: flex;align-items: center;font-size: 0.75rem;">
<!--                                身份证: {{ user_info.id_number }}-->
                                <el-input v-model="patient_number" placeholder="身份证号码" size="mini"></el-input>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: center">
                            <div style="margin-left:2px;margin-right:2px;width: 12rem;height:70%;display: flex;align-items: center;font-size: 0.75rem;">
                                <el-input v-model="patient_phone" placeholder="手机号码" size="mini"></el-input>
                            </div>
                            <div style="margin-left:2px;margin-right:2px;width: 12rem;height:70%;display: flex;align-items: center;font-size: 0.75rem;">
                                <el-input v-model="password" placeholder="患者初始密码" size="mini"></el-input>
                            </div>
                        </div>
                        <div style="height: 9.5rem;overflow-y: auto;font-size: 14px;border: 1px #dddddd solid;border-radius: 4px;margin-top: 10px;">
                            <table style="width: 100%;">
                                <thead>
                                <tr style="font-size: 1rem;">
                                    <th style="width: 12%;">序号</th>
                                    <th style="width: 35%;">时间</th>
                                    <th style="width: 20%;">上传者</th>
                                    <th style="width: 25%;"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr style="font-size: 1rem;" v-for="(item,index) in file_info" :key="index">
                                    <td style="text-align: center;">{{ index+1 }}</td>
                                    <td style="text-align: center;">{{ item.create_time }}</td>
                                    <td style="text-align: center;" v-if="item.doctor_id === 0">{{ item.getUser.name }}</td>
                                    <td style="text-align: center;" v-else>{{ item.getDoctor.name }}</td>
                                    <td style="text-align: center;">
                                        <el-button style="width: 80%;font-weight: bold;margin: 5px 1%;padding: 3px 0;font-size: 1rem;" type="primary" @click="viewFile(item)">查看</el-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
<!--                        <div style="height: 20%;display: flex;justify-content: center;">-->
<!--                            <el-button style="width: 30%;height: 2rem;line-height:0.1rem;font-weight: bold;font-size: 1rem;" type="primary">添加</el-button>-->
<!--                        </div>-->
                    </div>
                </div>
                <div style="width:100%;height:fit-content;margin-top: 10px;overflow-y: auto;">
                     <el-upload
                         class="upload-demo"
                         :action="Upload_Api_urls"
                         :on-preview="handlePreview"
                         :on-remove="handleRemove"
                         :before-upload="beforeUpload"
                         :on-success="handleSuccess"
                         :headers="{ token: this.token }"
                         multiple
                         :limit="3"
                         :on-exceed="handleExceed"
                         :file-list="fileList">
                         <el-button size="small" type="primary">文件直接上传</el-button>
<!--                         <el-button size="small" type="primary">高拍仪拍摄上传</el-button>-->
                     </el-upload>
                </div>
            </div>
            <div style="width: 25rem;">
                <div style="margin-top: 1%;display: flex;justify-content: space-between;">
                    <div style="width: 45%;height: 100%;">
                        <div style="width: 100%;display: flex;align-items: center;justify-content: left;margin-top: 1%;" v-for="index in selectNum" :key="index" >
                            <div style="width: 15%;margin-left: 6px;white-space: nowrap;"></div>
                            <div style="margin-top: -4px;">
                                <el-select size="mini" v-model="value[index]" filterable placeholder="请选择" @change="change">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div><i class="el-icon-caret-right" style="color: #0e92dd;"></i></div>
                        </div>
                        <div style="width: 100%;display: flex;;justify-content: center;">
                            <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #ffffff;" type="primary" @click="addSelect">添加</el-button>
                            <el-button style="width: 30%;font-weight: bold;margin: 10px 1%;padding: 5px 0;color: #ffffff;" type="primary" @click="deleteSelect">删除</el-button>
                        </div>
                    </div>
                    <div style="width: 50%;height: 14rem;border: 1px #dddddd solid;border-radius: 4px;overflow-y: auto;">
                        <div style="font-size: 14px;padding: 5px;word-wrap: break-word;overflow-wrap: break-word;" v-html="Introduction"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 50%;position: absolute;bottom: 0;right: 0;margin-right: 10px">
            <el-button style="width: 45%;font-weight: bold;margin: 10px 1%;padding: 8px 0;background-color: #e6f1fc;color: #0e92dd;" @click="success">保存</el-button>
            <el-button style="width: 45%;font-weight: bold;margin: 10px 1%;padding: 8px 0;background-color: #e6f1fc;color: #0e92dd;" @click="open">诊疗</el-button>
        </div>

        <!--//查看附件详情-->
        <el-dialog title="查看附件详情" :visible.sync="attachmentDetails" width="40%">
            <div style="width: 30rem;height: 30rem;margin: 2rem auto;">
                <img :src="attachmentUrl" style="width: 100%;height: 100%;object-fit:contain;" alt="Vue Image">
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    attachment,
    attachmentAdd,
    disease,
    filesAdd,
    medicalRecords, medicalRecordsDelAll,
    medicalRecordsEdit,
    user,
    userAdd,
    userEdit
} from "@/api";
import { Upload_Api_url } from '@/api/index.js';

export default {
    name: 'patientReg',
    data() {
        return {
            Upload_Api_urls: '',
            attachmentDetails: false,
            optionsSex: [{
                value: '1',
                label: '男'
            }, {
                value: '2',
                label: '女'
            }],
            options: [],
            input: [1,2,3,4,5],
            value: {},
            name: '',
            keyword: '',
            patient_name: '',
            patient_sex: '',
            patient_age: '',
            patient_number: '',
            patient_phone: '',
            password: '',
            user_info: [],
            file_info: [],
            fileList: [],
            token: '',
            patient_id: '',
            //下拉框添加数量
            selectNum: 1,
            user_id: '',
            Introduction: '',
            submitType: 1,
            attachmentUrl: '',
            user_role: '',
            user_name: '',
            userFilesList: '',
        }
    },
    mounted() {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id')
        this.diseaseInfo()
        this.Upload_Api_urls = Upload_Api_url
    },
    methods: {
        //查看附件
        viewFile(url){
            this.attachmentUrl = url.file_url
            this.attachmentDetails = true
        },
        //完善档案成功回调
        filesAddComplete(){
            this.$message({
                message: '添加成功',
                type: 'success'
            });
        },
        selectMenu(e) {
            this.$emit('menuIndex',e);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleSuccess(e){
            attachmentAdd({
                user_id: this.patient_id,
                doctor_id: this.user_id,
                file_url: e.data['file_path'],
                file_name: e.data['name'],
            }).then(res=>{
                if(res.data.code !== 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    attachment({
                        user_id: this.patient_id,
                        limit: 9999,
                        page: 1,

                    }).then(res=>{
                        if(res.data.code === 1){
                            this.file_info = res.data.data.data
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }
            })
            let array = {
                name: e.data['name'],
                url: e.data['url'],
            }
            this.fileList.push(array)
        },
        beforeUpload(file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            // const isWord = file.name.endsWith('.doc') || file.name.endsWith('.docx') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf');
            if (!isImage) {
            // if (!isWord) {
                this.$message.error('只能上传图片文件');
                return false;
            }
            const maxSize = 2 * 1024 * 1024; // 2Mb
            if (file.size > maxSize) {
                this.$message.error('文件大小不能超过2Mb');
                return false;
            }
            return true;
        },
        //跳转患者诊疗
        open(){
            this.$emit('menuIndex',5);
        },
        //身份证检索
        retrieval(){
            user({
                id_number: this.keyword,
            }).then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                    console.log(this.user_info)
                    this.patient_sex = res.data.data.sex
                    this.patient_id = res.data.data.id
                    this.patient_name = res.data.data.name
                    this.patient_sex = res.data.data.sex
                    this.patient_age = res.data.data.age
                    this.patient_number = res.data.data.id_number
                    this.patient_phone = res.data.data.tel
                    this.submitType = 2
                    localStorage.setItem('patient_id',this.patient_id)
                    attachment({
                        user_id: res.data.data.id,
                        limit: 9999,
                        page: 1,
                    }).then(res=>{
                        if(res.data.code === 1){
                            this.file_info = res.data.data.data
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                    medicalRecords({
                        user_id: res.data.data.id,
                    }).then(res=>{
                        if(res.data.code === 1){
                            this.selectNum = res.data.data.total
                            this.userFilesList = res.data.data.data
                            if(this.userFilesList.length > 0){
                                this.Introduction = this.userFilesList[0].disease.intro;
                                for (let i = 0; i < this.userFilesList.length; i++) {
                                    this.value[i+1] = this.userFilesList[i].disease_id
                                }
                            }
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //完善信息-下拉框选择事件
        change(e){
            for (let item of this.options) {
                if (item.id === e) {
                    this.Introduction = item.intro;
                    break;
                }
            }
        },
        //完善信息-新增下拉框
        addSelect(){
            if(this.selectNum >= 6 ) {
                this.selectNum = 6
                this.$message({
                    message: '最多添加六条',
                    type: 'success'
                });
            }else{
                this.selectNum ++
            }
        },
        //完善信息-新增下拉框
        deleteSelect(){
            if(this.selectNum <= 1 ) {
                this.selectNum = 1
                this.$message({
                    message: '最少保留一条',
                    type: 'success'
                });
            }else{
                this.selectNum --
                const keys = Object.keys(this.value);
                for (let i = this.selectNum; i < keys.length; i++) {
                    delete this.value[keys[i]];
                }
            }
        },
        //完善信息-获取疾病简介列表
        diseaseInfo(){
            disease().then(res=>{
                if(res.data.code === 1){
                    this.options = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //完成
        success(){
            const values = Object.values(this.value);
            const result = values.join(',');
            const set = new Set(values)
            let is_appear = set.size !== values.length
            if(is_appear){
                this.$message({
                    message: '相同的选项只会添加一条',
                    type: 'warning'
                });
            }
            if(this.submitType === 1){
                userAdd({
                     name: this.patient_name,
                     tel: this.patient_phone,
                     password: this.password,
                     id_number: this.patient_number,
                     sex: this.patient_sex,
                     age: this.patient_age,
                }).then(res=>{
                     if(res.data.code === 1){
                         filesAdd({
                             disease_id: result,
                             user_id: res.data.data.user_id,
                         }).then(res=>{
                             if(res.data.code === 1){
                                 this.$message({
                                     message: '创建成功',
                                     type: 'success'
                                 });
                             }else{
                                 this.$message({
                                     message: res.data.msg,
                                     type: 'warning'
                                 });
                             }
                         })
                     }else if(res.data.code == 401){
                         this.$emit('loginOuts');
                     }else{
                         this.$message({
                             message: res.data.msg,
                             type: 'warning'
                         });
                     }
                })
            }else{
                userEdit({
                    name: this.patient_name,
                    tel: this.patient_phone,
                    id_number: this.patient_number,
                    sex: this.patient_sex,
                    age: this.patient_age,
                    user_id: this.patient_id,
                }).then(res=>{
                    if(res.data.code === 1){
                        medicalRecordsDelAll({
                            user_id: this.patient_id,
                        }).then(res=>{
                            if(res.data.code === 1){
                                filesAdd({
                                    disease_id: result,
                                    user_id: this.patient_id,
                                }).then(res=>{
                                    if(res.data.code === 1){
                                        this.$message({
                                            message: '修改成功',
                                            type: 'success'
                                        });
                                    }else{
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                });
                            }
                        })
                    }else if(res.data.code == 401){
                        this.$emit('loginOuts');
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
    }
}
</script>

<style>
    .searchId .el-input__inner{
        border-radius: 50px !important;
    }
</style>

<style scoped>
    .content{
        position: relative;
        box-sizing: border-box;
        padding: 10px;
        height: 90%;
        width: 42%;
        background-color: #f7f7f7;
        border: 1px #d6d8dc solid;
    }
    .searchId{
        width: 95%;
        height: 12%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .condition{
        width: 95%;
        height: 85%;
        /*margin: auto;*/
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
    }
</style>
