<template>
    <div class="content">
        <div class="searchId">
            <div style="padding: 2%;width: 25%;">
                <el-button style="width: 100%;font-weight: bold;margin: 10px 0;padding: 8px 0;" type="primary" @click="Patient">新建</el-button>
            </div>
            <div style="width: 70%;font-size: 15px;padding: 10px;position: relative;">
                <el-input v-model="keyword" placeholder="请输入患者的身份证号码" prefix-icon="el-icon-search" style="width: 100%; border: none;"></el-input>

                <div style="padding: 2%;width: 25%;position: absolute;top: 50%;right: 0;transform: translateY(-50%);">
                    <el-button style="width: 80%;font-weight: bold;margin: 10px 0;padding: 3px 0;" type="primary" @click="retrieval">检索</el-button>
                </div>
            </div>
        </div>
        <div class="condition" id="xianShi">
            <div class="condition1" style="width: 25%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
                <el-input v-model="username" style="width: 100%; border: none;color: black;" :disabled="true">
                    <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">姓名:</div></template>
                </el-input>
            </div>
            <div class="condition1" style="width: 15%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
                <el-input v-model="sex" style="width: 100%; border: none;" :disabled="true">
                    <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">性别:</div></template>
                </el-input>
            </div>
            <div class="condition1" style="width: 20%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
                <el-input v-model="age" style="width: 100%; border: none;" :disabled="true">
                    <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">年龄:</div></template>
                </el-input>
            </div>
            <div class="condition1" id="sfz" style="width: 40%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
                <el-input v-model="sfz" style="width: 100%; border: none;" :disabled="true">
                    <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 20%;">身份证:</div></template>
                </el-input>
            </div>
            <div class="condition1" style="width: 5%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
                <i class="el-icon-circle-check" :class="{ 'green-icon': is_select === 1 }"></i>
            </div>
        </div>

        <div class="datalist">
            <div style="font-size: 16px;width: 97%;height: 15%;padding: 1.5% 0 0 3%;">资料列表:</div>
            <div style="width: 100%;height: 58%;display: flex;justify-content: center;flex-wrap: wrap;overflow-y: auto;">
                <div style="width: 42%;height: 49%;font-size:15px;display:flex;align-items:center;margin-top: 0px;" v-for="(item,index) in uploadData1" :key="index">
                    <span><i class="el-icon-document-remove"></i></span>
                    <span style="padding-left: 10px;">{{ item }}</span>
                </div>
            </div>
<!--            <div style="font-size: 16px;width: 97%;height: 15%;padding: 1.5% 0 0 3%;margin: auto;position: relative;">-->
<!--                <el-pagination-->
<!--                    :page-size="4"-->
<!--                    :pager-count="5"-->
<!--                    layout="prev, pager, next"-->
<!--                    :total="uploadData1.length"-->
<!--                    style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">-->
<!--                </el-pagination>-->
<!--            </div>-->
        </div>

        <div class="uploadFiles">
            <el-upload
                    class="upload-demo"
                    :action="Upload_Api_urls"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :headers="{ token: this.token }"
                    multiple
                    :limit="3"
                    :on-exceed="handleExceed"
                    :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
        </div>
    </div>
</template>
<script>
import {attachment, attachmentAdd, user} from "@/api";
import { Upload_Api_url } from '@/api/index.js';

export default {
    name: 'uploadData',
    data() {
        return {
            Upload_Api_urls: '',
            keyword: '',
            username: '',
            sex: '',
            age: '',
            sfz: '',
            is_select: 2,
            token: '',
            uploadData1: [],
            fileList: [],
            patient_id: '',
            user_id: '',
        }
    },
    mounted() {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id')
        this.Upload_Api_urls = Upload_Api_url
    },
    methods: {
        //跳转患者登记
        Patient(){
            this.$emit('menuIndex',3);
        },
        selectMenu(e) {
            this.$emit('menuIndex',e);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleSuccess(e){
            attachmentAdd({
                order_id: this.order_id,
                user_id: this.patient_id,
                doctor_id: this.user_id,
                file_url: e.data['file_path'],
                file_name: e.data['name'],
            }).then(res=>{
                if(res.data.code !== 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.uploadData1 = []
                    attachment({
                        user_id: this.patient_id,
                        page:1,
                        limit:999,
                    }).then(res=>{
                        if(res.data.code === 1){
                            for (let i = 0; i < res.data.data.data.length; i++) {
                                this.uploadData1.push(res.data.data.data[i].file_name)
                            }
                            console.log(this.uploadData1,'哈哈哈')
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }
            })
            let array = {
                name: e.data['name'],
                url: e.data['url'],
            }
            this.fileList.push(array)
        },
        beforeUpload(file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            // const isWord = file.name.endsWith('.doc') || file.name.endsWith('.docx') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf');
            if (!isImage) {
            // if (!isWord) {
                this.$message.error('只能上传图片文件');
                return false;
            }
            const maxSize = 2 * 1024 * 1024; // 2Mb
            if (file.size > maxSize) {
                this.$message.error('文件大小不能超过2Mb');
                return false;
            }
            return true;
        },
        //身份证检索
        retrieval(){
            user({
                id_number: this.keyword,
            }).then(res=>{
                if(res.data.code === 1){
                    this.username = res.data.data.name
                    if(res.data.data.sex === "1"){
                        this.sex = '男'
                    }else{
                        this.sex = '女'
                    }
                    this.is_select = 1
                    this.age = res.data.data.age
                    this.sfz = res.data.data.id_number
                    this.patient_id = res.data.data.id
                    attachment({
                        user_id: res.data.data.id,
                        page:1,
                        limit:999,
                    }).then(res=>{
                        this.uploadData1 = []
                        if(res.data.code === 1){
                            for (let i = 0; i < res.data.data.data.length; i++) {
                                this.uploadData1.push(res.data.data.data[i].file_name)
                            }
                            console.log(this.uploadData1,'哈哈哈')
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style>
.condition1 .el-input--prefix .el-input__inner{
    padding-left: 3rem;
}
#sfz .el-input--prefix .el-input__inner{
    padding-left: 4.5rem;
}
#xianShi .el-input.is-disabled .el-input__inner{
    color: black;
}
</style>
<style scoped>
  .content{
      height: 90%;
      width: 42%;
      background-color: #f7f7f7;
      border: 1px #d6d8dc solid;
  }
  .searchId{
      width: 95%;
      height: 12%;
      margin: 3% auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .condition{
      width: 90%;
      height: 12%;
      margin: 0.5rem auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px #dddddd solid;
  }
  .datalist{
      width: 90%;
      height: 35%;
      margin: 1rem auto;
      border: 1px #dddddd solid;
  }
  .uploadFiles{
      width: 90%;
      height: 20%;
      margin: 3% auto;
      overflow-y: auto;
  }
  .green-icon {
      color: #3aed3a;
  }
</style>
