<template>
    <div class="con">
        <div class="login_bo">
            <div class="login_title">{{ title }}</div>
            <el-form ref="form">
                <div v-if="type === 1">
                    <div style="width: 100%;text-align: center;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">用户名</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="tels1" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"
                                      style="width: 75%; border: none;" @blur="fill_password"></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 1%;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">密码</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="password1" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>
                        </el-form-item>
                    </div>
                    <div style="margin: 1% 3rem 4% 0;position: relative;">
                        <span style="text-align: left;position: absolute;right: 5%;top: 5%;cursor: pointer;font-size: 0.8rem;"><el-checkbox v-model="remember_password" :checked="remember_password" @change="is_password">记住密码</el-checkbox></span>
                    </div>
                    <div class="zhuCe" style="text-align: center;">
                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-top: 1rem;" size="mini" type="primary" @click="determineLogin">登 陆</el-button>
                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-top: 1rem;" size="mini" type="primary" @click="go_register" plain>注 册</el-button>
                    </div>
                </div>
                <div class="zhuCe_style" v-else>
                    <div style="width: 100%;text-align: center;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">真实姓名</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="form.name" placeholder="请输入真实姓名" prefix-icon="el-icon-user"
                                      style="width: 75%; border: none;"></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 1%;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">手机号</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="form.tel" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"
                                      style="width: 75%; border: none;"></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 1%;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">密码</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="form.password" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 1%;">
                        <div style="text-align: left;margin-left:13%;font-size: 12px;">身份证号码</div>
                        <el-form-item class="no-border">
                            <el-input size="small" v-model="form.id_number" placeholder="请输入身份证号码完成实名认证"
                                      prefix-icon="el-icon-bank-card" style="width: 75%; border: none;"></el-input>
                        </el-form-item>
                    </div>
                    <div class="dengLu" style="text-align: center;margin-top: 1%;">
                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-left:0;margin-top: 1rem;" type="primary" @click="register" plain>注 册</el-button>
                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-top: 1rem;" type="primary" @click="go_login">登 陆</el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { pwdLogin, register} from "@/api";
export default {
    name: 'loginInfo',
    data() {
        return {
            remember_password: false,
            tels1: '',
            password1: '',
            type: 1,//1未登录.2为注册
            title: '账号登录/注册',
            form: {
                name: '',
                tel: '',
                id_number: '',
                password: '',
            },
        }
    },
    created() {
        this.remember_password = localStorage.getItem(this.tels+'pass')
    },
    methods: {
        go_login(){
            this.type = 1
            this.title = '账号登录'
        },
        go_register(){
            this.type = 2
            this.title = '注册'
        },
        is_password(e){
            if(e === true){
                localStorage.setItem(this.tels1,this.password1)
                localStorage.setItem(this.tels1+'pass',true)
            }else{
                localStorage.removeItem(this.tels1);
                localStorage.removeItem(this.tels1+'pass')
            }
        },
        fill_password(){
            this.password1 = localStorage.getItem(this.tels1)
        },
        //用户登录
        determineLogin() {
            if (this.remember_password === true){
                localStorage.setItem(this.tels1,this.password1)
                localStorage.setItem(this.tels1+'pass',true)
            }else{
                localStorage.removeItem(this.tels1);
                localStorage.removeItem(this.tels1+'pass')
            }
            //手机号密码登录
            pwdLogin({
                tel: this.tels1,
                password: this.password1,
                role: 'user',
            }).then(res=>{
                if (res.data.code === 1){
                    this.$emit('patientLogin');
                    localStorage.setItem('token', res.data.data.token);
                    localStorage.setItem('user_id', res.data.data.user_id);
                    localStorage.setItem('user_role', res.data.data.user_role);
                    localStorage.setItem('en_user_name', res.data.data.en_name);
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        register(){
            register(this.form).then(res => {
                if (res.data.code === 1) {
                    this.$message({
                        message: '注册成功去登陆',
                        type: 'success'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style>
.zhuCe .el-button{
    margin-left: 0 !important;
}
.dengLu .el-button{
    margin-left: 0 !important;
}

</style>
<style scoped>
@media screen and (max-height: 600px) {
    .zhuCe_style .el-input__inner {

    }
}
.con{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_bo{
    width: 513px;
    height: 95%;
    border: 2px #70a5f3 solid;
    background-color: #f7f7f7;
    border-radius: 12px;
    padding-bottom: 87px;
}
.login_title{
    margin: 43px 0 35px 70px;
    font-size: 26px;
    font-weight: 500;
    color: #333;
}
.login_form{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.no-border{
    margin: 0;
    border: none;
    padding: 0;
}
</style>
