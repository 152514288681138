import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/HomeIndex.vue'
import testrec from '../views/testrtc.vue'
import invite from '../views/Invite.vue'
import forum from '../views/forum.vue'

Vue.use(Router)
// 可以导入其他页面组件

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/testrtc',
            name: 'testrtc',
            component: testrec
        },
        {
            path: '/invite',
            name: 'invite',
            component: invite
        },
        {
            path: '/forum',
            name: 'forum',
            component: forum
        },
    ]
})

export default router
