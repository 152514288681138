<template>
  <div class="invite-container" style="padding: 0">
    <comp-nav></comp-nav>
    <div class="content" :class="$isMobile && 'content-mobile'">
      <comp-room
        ref="videoims"
        type="invite"
        :sdkAppId="Number(sdkAppId)"
        :inviteUserSig="userSig"
        :userId="userId"
        :sdkSecretKey="sdkSecretKey"
        :roomId="Number(roomId)"></comp-room>
    </div>
  </div>
</template>

<script>
import compNav from '@/components/comp-nav.vue';
import CompRoom from '@/components/comp-rooms.vue';
import { getUrlParam, clearUrlParam } from '@/utils/utils.js';

export default {
  name: 'InviteApp',
  data() {
    return {
      sdkAppId: 1600028455,
      sdkSecretKey: '7086ca5085e21924ff96afc62da8ba0f4ee6c1ab4af34c55523e3f4cca5a5af9',
      userSig: '',
      userId: '',
      roomId: 0,
    };
  },
  components: {
    compNav,
    CompRoom,
  },
  mounted() {
      this.sdkAppId = 1600028455
      this.userSig = localStorage.getItem('userSig');
      this.userId = localStorage.getItem('userId')+`${parseInt(Math.random() * 100000000, 10)}`;
      this.roomId = localStorage.getItem('roomid');
    // this.userSig = getUrlParam('userSig');
    // this.userId = getUrlParam('userId');
    // this.roomId = Number(getUrlParam('roomId'));
    clearUrlParam();
  },
    methods: {
        videodata(e) {
          if (this.$refs.videoims) {
              this.$refs.videoims.videodata(e)
          }
      }
  }
};
</script>

<style lang="scss" scoped>
 .content {
    width: 100%;
    margin: 0 auto;
    max-width: 1320px;
    //padding-top: 30px;
    &.content-mobile {
      width: 100%;
      padding: 30px 16px 20px;
    }
    .invite-tips {
      width: 100%;
      height: 60px;
      color: #084298;
      background-color: #cfe2ff;
      position: relative;
      padding: 1rem 1rem;
      margin-bottom: 1rem;
      border: 1px solid #b6d4fe;
      border-radius: 0.25rem;
    }
 }
</style>
