<template>
    <div class="con">
        <div class="login_bo">
                <div class="login_title">账号登录</div>
                <el-form ref="form">
<!--                    <div v-if="loginType === 1">-->
<!--                        <el-form-item class="no-border">-->
<!--                            <el-input v-model="tel" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;font-size: 0.8rem;"></el-input>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item style="margin: 1.5rem 0 0 0;border: none; padding: 0;">-->
<!--                            <div style="display: flex;justify-content: center">-->
<!--                                <el-input v-model="code" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;font-size: 0.8rem;"></el-input>-->
<!--                                <el-button style="width: 30%;font-weight: bold;padding: 0.2rem 0.5rem;font-size: 0.8rem;" type="primary" @click="startCountdown(1)">{{ codeText1 }}</el-button>-->
<!--                            </div>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
                    <div class="login_form">
                        <div style="width: 100%;text-align: center;">
                            <div style="text-align: left;margin-left:13%;font-size: 12px;">用户名</div>
                            <el-form-item class="no-border">
                                <el-input v-model="tels" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;font-size: 0.8rem;" @blur="fill_password"></el-input>
                            </el-form-item>
                        </div>
                        <div style="width: 100%;text-align: center;margin-top: 1rem;">
                            <div style="text-align: left;margin-left:13%;">密码</div>
                            <el-form-item>
                                <el-input v-model="passWord" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;font-size: 0.8rem;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="margin: 0 3rem 2rem 0;position: relative;">
<!--                        <span style="text-align: left;position: absolute;left: 5%;top: 5%;cursor: pointer;font-size: 0.8rem;" @click="isUpdatePass = true">忘记密码</span>-->
                        <span style="text-align: left;position: absolute;right: 5%;top: 5%;cursor: pointer;font-size: 0.8rem;"><el-checkbox v-model="remember_password" :checked="remember_password" @change="is_password">记住密码</el-checkbox></span>
                    </div>
<!--                    <el-form-item style="margin: 3rem 0;">-->
                    <div style="text-align: center;">
                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-top: 1rem;" type="primary" @click="onSubmit">登 陆</el-button>
<!--                        <el-button style="width: 75%;font-weight: bold;font-size: 1rem;margin-left:0;margin-top: 1rem;" type="primary" plain>注 册</el-button>-->
                    </div>

<!--                    </el-form-item>-->
                </el-form>
        </div>


        <!--//修改密码-->
<!--        <el-dialog title="重置密码" :visible.sync="isUpdatePass" width="30%">-->
<!--            <el-form ref="form">-->
<!--                <div>-->
<!--                    <el-form-item class="no-border">-->
<!--                        <el-input v-model="tels1" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;font-size: 0.8rem;"></el-input>-->
<!--                    </el-form-item>-->
<!--&lt;!&ndash;                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div style="display: flex;justify-content: center">&ndash;&gt;-->
<!--&lt;!&ndash;                            <el-input v-model="codes1" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;font-size: 0.8rem;"></el-input>&ndash;&gt;-->
<!--&lt;!&ndash;                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;font-size: 0.5rem;" type="primary" @click="startCountdown(2)">{{ codeText2 }}</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </el-form-item>&ndash;&gt;-->
<!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
<!--                        <el-input v-model="id_number" placeholder="请输入身份证号" prefix-icon="el-icon-user" style="width: 75%; border: none;"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
<!--                        <el-input v-model="passWords1" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>-->
<!--                    </el-form-item>-->
<!--                </div>-->
<!--                <el-form-item style="margin: 5% 0;">-->
<!--                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updatePwd">确定修改</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--            <div style="width: 100%;height: 20px;"></div>-->
<!--        </el-dialog>-->
    </div>
</template>
<script>
import {sendSmS, telLogin, pwdLogin, resetPassword, config} from "@/api";
import {TUICallKit, TUICallKitServer, TUICallType} from "@tencentcloud/call-uikit-vue2.6";
import genTestUserSig from "@/debug/GenerateTestUserSig-es.js";
export default {
    name: 'loginInfo',
    data() {
        return {
            //手机号验证码
            tel: '',
            code: '',
            //手机号密码
            tels: '',
            passWord: '',
            //忘记密码
            tels1: '',
            codes1: '',
            passWords1: '',
            id_number: '',
            //登录类型 1为验证码登录,2为密码登录
            loginType: 2,
            //定时器
            timer: null,
            count: 60,
            //发送验证码按钮内容
            codeText1: '获取验证码',
            codeText2: '获取验证码',
            codeButType: 1,
            isUpdatePass: false,
            SDKAppID: '',
            SecretKey: '',
            en_user_name: '',
            remember_password: false,
        }
    },
    created() {
        this.remember_password = localStorage.getItem(this.tels+'pass')
    },
    methods: {
        is_password(e){
            if(e === true){
                localStorage.setItem(this.tels,this.passWord)
                localStorage.setItem(this.tels+'pass',true)
            }else{
                localStorage.removeItem(this.tels);
                localStorage.removeItem(this.tels+'pass')
            }
        },
        fill_password(){
            this.passWord = localStorage.getItem(this.tels)
        },
        //获取验证码
        startCountdown(type){
            if(this.codeButType === 1){
                this.codeButType = 2
                let phone = ''
                if(type === 1){
                    phone = this.tel
                }else{
                    phone = this.tels1
                }
                sendSmS({
                    tel:phone
                }).then(res=>{
                    if(res.data.code === 1){
                        this.timer = setInterval(() => {
                            this.count--;
                            if (this.count <= 0) {
                                clearInterval(this.timer);
                                this.timer = null;
                                this.count = 60;
                                if(type === 1){
                                    this.codeText1 = '获取验证码';
                                }else{
                                    this.codeText2 = '获取验证码';
                                }
                                this.codeButType = 1
                            } else {
                                this.codeText = `${this.count} 秒后重发`;
                                if(type === 1){
                                    this.codeText1 = `${this.count} 秒后重发`;
                                }else{
                                    this.codeText2 = `${this.count} 秒后重发`;
                                }
                            }
                        }, 1000);
                    }else{
                        this.codeButType = 1
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        //初始化视频
        init(user_id,en_name,user_role) {
            config().then(res=>{
                if(res.data.code === 1){
                    this.SDKAppID = this.decrypt(res.data.data.sdk_app_id)
                    this.SecretKey = this.decrypt(res.data.data.sdk_secret_key)
                    try {
                        const {userSig} = genTestUserSig({
                            userID: en_name+'_'+user_role+'_'+user_id.toString(),
                            SDKAppID: Number(this.SDKAppID),
                            SecretKey: this.SecretKey,
                        });
                        // 初始化
                        TUICallKitServer.init({
                            SDKAppID: Number(this.SDKAppID),
                            userID: en_name+'_'+user_role+'_'+user_id.toString(),
                            userSig,
                            // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
                        })
                        // alert("[TUICallKit] Initialization succeeds.");
                    } catch (error) {
                        // alert(`[TUICallKit] Initialization failed. Reason: ${error}`);
                        this.$message({
                            message: error,
                            type: 'warning'
                        });
                    }
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        decrypt(encodedStr){
            return atob(encodedStr);
        },
        //登录
        onSubmit(){
            // this.$emit('loginUser',2);
            // sendSmS
            if(this.loginType === 1){
                //手机号验证码登录
                telLogin({
                    tel: this.tel,
                    role: 'doctor',
                    code: this.code,
                }).then(res=>{
                    if (res.data.code === 1){
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('user_id', res.data.data.user_id);
                        localStorage.setItem('user_role', res.data.data.user_role);
                        localStorage.setItem('en_user_name', res.data.data.en_name);
                        localStorage.setItem('medical_department_id', res.data.data.medical_department_id);
                        localStorage.setItem('admin_flag', res.data.data.admin_flag);
                        this.init(res.data.data.user_id,res.data.data.en_name,res.data.data.user_role)
                        this.$emit('loginUser');
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }else{
                if (this.remember_password === true){
                    localStorage.setItem(this.tels,this.passWord)
                    localStorage.setItem(this.tels+'pass',true)
                }else{
                    localStorage.removeItem(this.tels);
                    localStorage.removeItem(this.tels+'pass')
                }
                console.log(localStorage.getItem('Invitation_type'))
                const Invitation_type = localStorage.getItem('Invitation_type')
                //手机号密码登录
                localStorage.setItem('tel', this.tels);
                localStorage.setItem('password', this.passWord);
                pwdLogin({
                    tel: this.tels,
                    password: this.passWord,
                    role: 'doctor',
                }).then(res=>{
                    if (res.data.code === 1){
                        this.tels = ''
                        this.passWord = ''
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('user_id', res.data.data.user_id);
                        localStorage.setItem('user_role', res.data.data.user_role);
                        localStorage.setItem('en_user_name', res.data.data.en_name);
                        localStorage.setItem('medical_department_id', res.data.data.medical_department_id);
                        localStorage.setItem('admin_flag', res.data.data.admin_flag);

                      this.init(res.data.data.user_id,res.data.data.en_name,res.data.data.user_role)

                      console.log(Invitation_type,'啦啦啦')
                        if(Invitation_type == 2){
                            localStorage.setItem('Invitation_type',1)
                            this.$emit('backInvitation_type');
                        }else{
                            localStorage.setItem('Invitation_type',1)
                            this.$emit('loginUser');
                        }

                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        // passWords(){
        //     this.loginType = 2
        // },
        // phoneCode(){
        //     this.loginType = 1
        // },
        //忘记密码
        updatePwd(){
            resetPassword({
                tel: this.tels1,
                id_number: this.id_number,
                new_password: this.passWords1,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$emit('loginOut');
                    this.isUpdatePass = false
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('user_role');
                    localStorage.removeItem('en_user_name');
                    localStorage.removeItem(this.tels1);
                    localStorage.removeItem(this.tels1+'pass');
                    this.$message({
                        message: '重置成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
    .con{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login_bo{
        width: 513px;
        height: 95%;
        border: 2px #70a5f3 solid;
        background-color: #f7f7f7;
        border-radius: 12px;
        padding-bottom: 87px;
    }
    .login_title{
        margin: 43px 0 35px 70px;
        font-size: 26px;
        font-weight: 500;
        color: #333;
    }
    .login_form{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .no-border{
        margin: 0;
        border: none;
        padding: 0;
    }
</style>
