<template>
    <div>
        <div class="con_but" v-if="type === 1" @click="chinese_doctor">
            <img src="@/assets/chinese_doctor.png" class="full-width-image">
        </div>
    </div>
</template>
<script>

export default {
    name: 'patientUser_img',
    data() {
        return {
            type: 1,
        }
    },
    mounted() {

    },
    methods: {
        chinese_doctor(){
            this.$emit('chinese_doctor');
        },
    }
}
</script>
<style scoped>
.con_but{
    margin-top: 30%;
    width: 100%;
    height: 28vh;
    margin-left: 40%;
//border-radius: 10px;
//border: 5px #166aca solid;
}
.full-width-image{
    width: 100%;
    height: 100%;
}
</style>
